import { NavigateOptions, To, useNavigate, useParams } from "react-router-dom";
import humanizeDuration from "humanize-duration";

interface UseI18n {
  locale: string;
  navigate(to: To, options?: NavigateOptions, locale?: string): void;
  getLink(to: To, locale?: string): To;
  formatDuration(milliseconds: number): string;
}

const getLink = (to: To, locale?: string): To => {
  if (typeof to === "string") {
    return `/${locale}${to}`;
  }

  to.pathname = `/${locale}${to.pathname}`;

  return to;
};

export function useI18n(): UseI18n {
  const { locale: currentLocale } = useParams();
  const navigate = useNavigate();

  return {
    locale: String(currentLocale),

    getLink(to, locale) {
      return getLink(to, locale || String(currentLocale));
    },
    navigate(to, options, locale) {
      return navigate(getLink(to, locale || String(currentLocale)), options);
    },

    formatDuration(milliseconds) {
      return humanizeDuration(milliseconds, {
        language: currentLocale,
        maxDecimalPoints: 0,
      });
    },
  };
}
