import React from "react";
import {
  Company,
  Permission,
  useCompanyByIdQuery,
  usePaginateCompaniesQuery,
} from "../../../hooks.generated";
import { usePagination } from "../../../hooks/usePagination";
import Pagination from "../../utils/Pagination";
import {
  Alert,
  Avatar,
  Box,
  Grid,
  LinearProgress,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
} from "@mui/material";
import { Business } from "@mui/icons-material";
import { Trans } from "@lingui/macro";
import { useSearchParams } from "react-router-dom";
import CompanyDetails from "../../elements/CompanyDetails";
import useCurrentUser from "../../../hooks/useCurrentUser";

export const companiesPagePermissions = [Permission.CompanyIndex];

const CompaniesPage = () => {
  const { hasPermission } = useCurrentUser();

  const [searchParameters, setSearchParams] = useSearchParams();
  const { pagination, changePage } = usePagination({
    limit: 200,
  });

  const {
    data: companiesData,
    error: companiesError,
    loading: companiesLoading,
  } = usePaginateCompaniesQuery({
    variables: {
      search: searchParameters.get("search"),
      pagination,
    },

    skip: !hasPermission([Permission.CompanyIndex]),
  });

  const companyId = searchParameters.get("company");

  const {
    data: companyData,
    error: companyError,
    loading: companyLoading,
  } = useCompanyByIdQuery({
    variables: {
      id: String(companyId),
    },

    skip: !companyId || !hasPermission([Permission.CompanyIndex]),
  });

  if (companiesError) {
    throw companiesError;
  }

  if (companyError) {
    throw companyError;
  }

  if (!hasPermission([Permission.CompanyIndex])) {
    return <></>;
  }

  return (
    <>
      <Grid container sx={{ flex: 1, overflow: "auto", height: "100%" }}>
        <Grid
          item
          xs={12}
          sm={3}
          padding={3}
          sx={{ display: "flex", flexDirection: "column", height: "100%" }}
        >
          <Box
            component={Paper}
            padding={2}
            sx={{ display: "flex", flexDirection: "column", height: "100%" }}
          >
            {!companiesLoading &&
              !companiesData?.paginateCompanies.companies?.data.length && (
                <Alert severity="info">
                  <Trans>No companies found.</Trans>
                </Alert>
              )}

            {companiesLoading && <LinearProgress />}

            <List
              sx={{
                width: "100%",
                position: "relative",
                overflow: "auto",
              }}
            >
              {companiesData?.paginateCompanies.companies?.data.map(
                (company) => (
                  <ListItem key={company.id} dense>
                    <ListItemButton
                      onClick={() => {
                        searchParameters.set("company", company.id);
                        setSearchParams(searchParameters);
                      }}
                    >
                      <ListItemIcon>
                        <Avatar
                          alt={company.names.join(', ')}
                          src={company.logo || ""}
                          imgProps={{ sx: { objectFit: "contain" } }}
                        >
                          <Business />
                        </Avatar>
                      </ListItemIcon>

                      <ListItemText
                        primary={company.names.join(', ')}
                        secondary={company.reference}
                      />
                    </ListItemButton>
                  </ListItem>
                )
              )}
            </List>

            <Pagination
              current={companiesData?.paginateCompanies.companies?.currentPage}
              total={companiesData?.paginateCompanies.companies?.total}
              limit={companiesData?.paginateCompanies.companies?.limit}
              onPageChange={(page) => changePage(page)}
            />
          </Box>
        </Grid>

        <Grid item xs={12} sm={9} padding={3}>
          <Box
            component={Paper}
            sx={{ display: "flex", flexDirection: "column", height: "100%" }}
          >
            {(companyLoading || !companyData?.companyById.company) && (
              <Box padding={2}>
                {!companyLoading && !companyData?.companyById.company && (
                  <Alert severity="info">
                    <Trans>Company not found.</Trans>
                  </Alert>
                )}

                {companyLoading && <LinearProgress />}
              </Box>
            )}

            {!!companyData?.companyById.company && (
              <CompanyDetails
                company={companyData?.companyById.company as Company}
              />
            )}
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default CompaniesPage;
