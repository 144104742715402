import React from "react";
import { Box, Pagination as MuiPagination } from "@mui/material";

interface PaginationProps {
  total?: number;
  limit?: number;
  current?: number;

  onPageChange(page: number): void;
}

const Pagination = ({
  total: totalItems,
  limit: limitItems,
  current: currentItems,
  onPageChange,
}: PaginationProps) => {
  const total = totalItems || 0;
  const limit = limitItems || 0;
  const current = currentItems || 0;

  const totalPages = Math.ceil(total / limit);

  if (totalPages <= 1 || isNaN(totalPages)) {
    return null;
  }

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        p: 2,
      }}
    >
      <MuiPagination
        count={totalPages}
        page={current}
        onChange={(_, page) => {
          onPageChange(page);
        }}
      />
    </Box>
  );
};

export default Pagination;
