import { Theme } from "@emotion/react";
import { createTheme } from "@mui/material/styles";

export const theme: Theme = createTheme({
  backgroundColor: "#e7e7e7",

  palette: {
    primary: {
      main: "#396ca1",
    },
    secondary: {
      main: "#224e38",
    },
  },

  typography: {
    fontFamily: "Montserrat",

    fontSize: 14,

    h1: {
      fontSize: 32,
    },

    h2: {
      fontSize: 24,
    },

    h3: {
      fontSize: 20,
    },

    h4: {
      fontSize: 16,
    },

    h5: {
      fontSize: 12,
    },

    h6: {
      fontSize: 8,
    },
  },

  spacing: 4,

  components: {
    MuiContainer: {
      styleOverrides: {
        maxWidthLg: "800px",
        maxWidthMd: "800px",
        maxWidthSm: "800px",
        maxWidthXl: "800px",
        maxWidthXs: "800px",
      },
    },

    MuiButton: {
      styleOverrides: {
        containedPrimary: {
          color: "#ffffff",
        },
      },
    },
  },
});
