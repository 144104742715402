import React, { useEffect, useMemo } from "react";
import {
  Background,
  Controls,
  Edge,
  Node,
  Position,
  ReactFlow,
  ReactFlowProvider,
  XYPosition,
  useReactFlow,
} from "reactflow";
import {
  Company,
  IpAddress,
  RelatedIpAddress,
  Domain,
  RelatedDomain,
  EnrichmentSource,
  Permission,
  usePrioritizeCompanyEnrichersMutation,
} from "../../hooks.generated";
import dagre from "dagre";
import { Avatar, Box, Button, Typography } from "@mui/material";
import { getCommunicationLink } from "../../utils/communication";
import { Business } from "@mui/icons-material";
import useCurrentUser from "../../hooks/useCurrentUser";

interface CompanyDetailsProps {
  company: Company;
}

type RenderFlowProps = CompanyDetailsProps;

type DagreNode = Omit<Node, "position"> & {
  position?: XYPosition;
};

const getLayoutedElements = (nodes: DagreNode[], edges: Edge[]) => {
  const dagreGraph = new dagre.graphlib.Graph();
  dagreGraph.setDefaultEdgeLabel(() => ({}));

  const nodeWidth = 400;
  const nodeHeight = 36;

  dagreGraph.setGraph({ rankdir: "LR" });

  nodes.forEach((node) => {
    dagreGraph.setNode(node.id, { width: nodeWidth, height: nodeHeight });
  });

  edges.forEach((edge) => {
    dagreGraph.setEdge(edge.source, edge.target);
  });

  dagre.layout(dagreGraph);

  nodes.forEach((node) => {
    const nodeWithPosition = dagreGraph.node(node.id);

    // We are shifting the dagre node position (anchor=center center) to the top left
    // so it matches the React Flow node anchor point (top left).
    node.position = {
      x: nodeWithPosition.x - nodeWidth / 2,
      y: nodeWithPosition.y - nodeHeight / 2,
    };

    node.width = nodeWidth;
    node.height = nodeHeight;

    node.style = {
      wordBreak: "break-all",
    };

    return node;
  });

  return { nodes: nodes as Node[], edges };
};

const CompanyDetails = ({ company }: CompanyDetailsProps) => {
  const { hasPermission } = useCurrentUser();
  const [prioritizeCompanyEnrichers] = usePrioritizeCompanyEnrichersMutation();
  const domains: RelatedDomain[] = (company.relatedDomains || []).filter(
    (relatedDomain) => {
      return (
        relatedDomain.source === EnrichmentSource.GooglePlaces ||
        relatedDomain.source === EnrichmentSource.GoogleSearch ||
        relatedDomain.source === EnrichmentSource.BedrijvenmonitorInfo
      );
    }
  );

  return (
    <>
      {/* // todo: company details via tabs? */}
      <Box padding={2} sx={{ display: "flex", alignItems: "center" }}>
        <Avatar
          alt={company.names.join(', ')}
          imgProps={{ sx: { objectFit: "contain" } }}
          src={company.logo || ""}
          sx={{
            marginRight: "10px",
            width: company.logo ? 100 : undefined,
            height: company.logo ? 100 : undefined,
          }}
        >
          <Business />
        </Avatar>

        <Box>
          <Typography variant="h2">{company.names.join(', ')}</Typography>
          <Typography variant="h4">{company.reference}</Typography>
        </Box>

        {hasPermission([Permission.CompanyPrioritizeEnrichers]) && (
          <Button
            sx={{ marginLeft: "auto" }}
            variant="contained"
            onClick={async () => {
              await prioritizeCompanyEnrichers({
                variables: {
                  companyId: company.id,
                },
              });
            }}
          >
            Prioritize enrichers
          </Button>
        )}
      </Box>

      {(company.addresses || []).length > 0 && (
        <Box padding={2}>
          {company.addresses?.map((address) => {
            const parts = [
              `${address.street ? `${address.street} ` : ""}${
                address.houseNumber || ""
              }${address.houseNumberAddition || ""}`,
              address.zipcode,
              address.place,
              address.country?.name,
            ].filter((part) => part && String(part).length);

            return (
              <div key={address.id}>
                <strong>{address.source}</strong>: {parts.join(", ")}
              </div>
            );
          })}
        </Box>
      )}

      {(company.communications || []).length > 0 && (
        <Box padding={2}>
          {company.communications?.map((communication) => {
            return (
              <div key={communication.id}>
                <strong>{communication.source}</strong>:{" "}
                <a href={getCommunicationLink(communication)}>
                  {communication.value}
                </a>
              </div>
            );
          })}
        </Box>
      )}

      {(company.tags || []).length > 0 && (
        <Box padding={2}>
          {company.tags?.map((tag) => {
            return (
              <div key={tag.id}>
                <strong>
                  {tag.source}, {tag.type}
                </strong>
                : {tag.reference} - {tag.name}
              </div>
            );
          })}
        </Box>
      )}

      {(domains || []).length > 0 && (
        <Box padding={2}>
          {domains.map((domain) => {
            return (
              <div key={domain.domain.id}>
                <strong>{domain.source}</strong>:{" "}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={`//${domain.domain.fqdn}`}
                >
                  {domain.domain.fqdn}
                </a>
              </div>
            );
          })}
        </Box>
      )}

      <ReactFlowProvider>
        <RenderFlow company={company} />
      </ReactFlowProvider>
    </>
  );
};

function RenderFlow({ company }: RenderFlowProps) {
  const reactFlowInstance = useReactFlow();

  const companyGraph = useMemo(() => {
    const disableOptions = {
      draggable: false,
      deletable: false,
      connectable: false,
    };

    const domains = (company.relatedDomains || []).reduce<Domain[]>(
      (domains, domain) => {
        if (
          !domains.find((domainToFind) => domainToFind.id === domain.domain.id)
        ) {
          domains.push(domain.domain);
        }

        return domains;
      },
      []
    );

    const ipAddresses: IpAddress[] = [
      ...(company.relatedIpAddresses || []).reduce<IpAddress[]>(
        (ipAddresses, ipAddress) => {
          if (
            !ipAddresses.find(
              (ipAddressToFind) => ipAddressToFind.id === ipAddress.ipAddress.id
            )
          ) {
            ipAddresses.push(ipAddress.ipAddress);
          }

          return ipAddresses;
        },
        []
      ),

      ...domains.reduce<IpAddress[]>((ipAddresses, domain) => {
        for (const ipAddress of domain.relatedIpAddresses || []) {
          if (
            !ipAddresses.find(
              (ipAddressToFind) => ipAddressToFind.id === ipAddress.ipAddress.id
            )
          ) {
            ipAddresses.push(ipAddress.ipAddress);
          }
        }

        return ipAddresses;
      }, []),
    ];

    const domainsIpAddresses = domains.reduce<[Domain, RelatedIpAddress][]>(
      (domainIpAddresses, domain) => {
        const related: [Domain, RelatedIpAddress][] = (
          domain.relatedIpAddresses || []
        ).map((domainIpAddress) => {
          return [domain, domainIpAddress];
        });

        return [...domainIpAddresses, ...related];
      },
      []
    );

    const edges: Edge[] = [
      ...(company.relatedIpAddresses || []).map((companyIpAddress) => {
        const sources = (company.relatedIpAddresses || []).filter(
          (relatedIpAddress) =>
            relatedIpAddress.ipAddress.id === companyIpAddress.ipAddress.id
        );

        return {
          id: "",
          source: "company-" + company.id,
          target: "ip-address-" + companyIpAddress.ipAddress.id,
          label: sources
            .map((source) => source.source + " (" + source.score + "%)")
            .join(", "),
        };
      }),

      ...(company.relatedDomains || []).map((domainIpAddress) => {
        const sources = (company.relatedDomains || []).filter(
          (relatedDomain) =>
            relatedDomain.domain.id === domainIpAddress.domain.id
        );

        return {
          id: "",
          source: "company-" + company.id,
          target: "domain-" + domainIpAddress.domain.id,
          label: sources
            .map((source) => source.source + " (" + source.score + "%)")
            .join(", "),
        };
      }),

      ...domainsIpAddresses.map(([domain, domainIpAddress]) => {
        const sources = domainsIpAddresses.filter(
          (relatedDomain) =>
            relatedDomain[1].ipAddress.id === domainIpAddress.ipAddress.id
        );

        return {
          id: "",
          source: "domain-" + domain.id,
          target: "ip-address-" + domainIpAddress.ipAddress.id,
          label: sources
            .map((source) => source[1].source + " (" + source[1].score + "%)")
            .join(", "),
        };
      }),
    ];

    return getLayoutedElements(
      [
        {
          id: "company-" + company.id,
          data: {
            label: company.names.join(', '),
          },

          type: "input",
          sourcePosition: Position.Right,

          ...disableOptions,
        },

        ...domains.map((domain) => {
          return {
            id: "domain-" + domain.id,
            data: {
              label: domain.fqdn,
            },

            sourcePosition: Position.Right,
            targetPosition: Position.Left,

            ...disableOptions,
          };
        }),

        ...ipAddresses.map((ipAddress) => {
          return {
            id: "ip-address-" + ipAddress.id,
            data: {
              label: ipAddress.address,
            },

            type: "output",
            targetPosition: Position.Left,

            ...disableOptions,
          };
        }),
      ],
      edges.map((edge, index) => {
        edge.id = String(index);

        return edge;
      })
    );
  }, [company]);

  useEffect(() => {
    setTimeout(() => {
      reactFlowInstance.fitView();
    }, 1);
  }, [company, reactFlowInstance]);

  return (
    <ReactFlow edges={companyGraph.edges} nodes={companyGraph.nodes} fitView>
      <Background />
      <Controls showInteractive={false} />
    </ReactFlow>
  );
}

export default CompanyDetails;
