import React, { PropsWithChildren } from "react";
import { Navigate } from "react-router-dom";
import useCurrentUser from "../../hooks/useCurrentUser";
import { useI18n } from "../../hooks/useI18n";

const Guest = ({ children }: PropsWithChildren<{}>) => {
  const { getLink } = useI18n();
  const { user } = useCurrentUser();

  if (user) {
    return <Navigate to={getLink("/dashboard")} replace />;
  }

  return <>{children}</>;
};

export default Guest;
