import {
  Alert,
  Avatar,
  Box,
  Chip,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Typography,
} from "@mui/material";
import React from "react";
import PaginateTrackedEvents from "./PaginateTrackedEvents";
import { Trans } from "@lingui/macro";
import { useTrackedSessionByIdQuery } from "../../../hooks.generated";
import { i18n } from "@lingui/core";
import { Business } from "@mui/icons-material";
import { useI18n } from "../../../hooks/useI18n";

interface TrackedSessionDetailsProps {
  trackedSessionId: string;
}

const TrackedSessionDetails = ({
  trackedSessionId,
}: TrackedSessionDetailsProps) => {
  const { formatDuration } = useI18n();
  const { data: trackedSessionData, error: trackedSessionError } =
    useTrackedSessionByIdQuery({
      variables: {
        id: trackedSessionId,
      },
    });

  if (trackedSessionError) {
    throw trackedSessionError;
  }

  const trackedSession = trackedSessionData?.trackedSessionById?.trackedSession;

  return (
    <Box
      component={Paper}
      padding={2}
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        overflow: "auto",
      }}
    >
      <Typography variant="h2">
        <Trans>Tracked session</Trans>
      </Typography>

      {!!trackedSession && (
        <>
          <Box>
            <Chip
              label={
                <Trans>
                  Started at{" "}
                  {i18n.date(trackedSession.start, {
                    timeStyle: "medium",
                    dateStyle: "medium",
                  })}
                </Trans>
              }
              sx={{ mr: 2 }}
              size="small"
            />

            {!!trackedSession.latestEventAt && (
              <Chip
                label={
                  <Trans>
                    Latest event at{" "}
                    {i18n.date(trackedSession.latestEventAt, {
                      timeStyle: "medium",
                      dateStyle: "medium",
                    })}
                  </Trans>
                }
                sx={{ mr: 2 }}
                size="small"
              />
            )}

            <Chip
              label={
                <Trans>
                  {formatDuration(
                    new Date(trackedSession.start).getTime() -
                      new Date().getTime()
                  )}{" "}
                  ago
                </Trans>
              }
              sx={{ mr: 2 }}
              size="small"
            />
          </Box>

          {(trackedSession.resolvedCompanies || []).length > 0 && (
            <Box sx={{ mt: 5 }}>
              <Typography variant="h3">
                <Trans>Companies in this session</Trans>
              </Typography>

              <List>
                {(trackedSession.resolvedCompanies || []).map(
                  (resolvedCompany) => {
                    return (
                      <ListItem dense key={resolvedCompany.company.id}>
                        <ListItemIcon>
                          <Avatar
                            alt={resolvedCompany.company.names.join(', ')}
                            src={resolvedCompany.company.logo || ""}
                            imgProps={{ sx: { objectFit: "contain" } }}
                            sx={{
                              width: 50,
                              height: 50,
                            }}
                          >
                            <Business />
                          </Avatar>
                        </ListItemIcon>

                        <ListItemText
                          primary={resolvedCompany.company.names.join(', ')}
                          secondary={resolvedCompany.company.reference}
                        ></ListItemText>

                        <Box>
                          <Chip
                            label={
                              <Trans>Score: {resolvedCompany.score}</Trans>
                            }
                            sx={{ mr: 2 }}
                            size="small"
                          />

                          <Chip
                            label={
                              <Trans>
                                Resolved at{" "}
                                {i18n.date(resolvedCompany.resolvedAt, {
                                  timeStyle: "medium",
                                  dateStyle: "medium",
                                })}
                              </Trans>
                            }
                            sx={{ mr: 2 }}
                            size="small"
                          />
                        </Box>
                      </ListItem>
                    );
                  }
                )}
              </List>
            </Box>
          )}
          {(trackedSession.resolvedCompanies || []).length <= 0 && (
            <Alert color="info" sx={{ mt: 5 }}>
              <Trans>No companies bound to session yet</Trans>
            </Alert>
          )}
        </>
      )}

      <Box sx={{ mt: 5 }}>
        <Typography variant="h3">
          <Trans>Tracked events</Trans>
        </Typography>
        <PaginateTrackedEvents trackedSessionId={trackedSessionId} />
      </Box>
    </Box>
  );
};

export default TrackedSessionDetails;
