import React from "react";
import { ErrorOption, useForm } from "react-hook-form";
import { object, string } from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { t, Trans } from "@lingui/macro";
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
} from "@mui/material";
import CountrySelect from "../utils/CountrySelect";
import {
  Company,
  CreateCompanyResponseStatus,
  useCreateCompanyMutation,
} from "../../hooks.generated";
import MissingCompanySelect from "../utils/MissingCompanySelect";

interface CreateCompanyFormProps {
  onSubmit(company: Company): void;
  onCancel?(): void;
}

interface CreateCompanyData {
  reference: string;
  country: string;
}

const createCompanyValidation = object({
  reference: string().required(),
  country: string().required(),
});

const CreateCompanyForm = ({ onSubmit, onCancel }: CreateCompanyFormProps) => {
  const [createCompany, { loading }] = useCreateCompanyMutation();

  const {
    handleSubmit,
    setError,
    watch,
    control,
    register: registerField,
    formState: { errors },
  } = useForm<CreateCompanyData>({
    resolver: yupResolver(createCompanyValidation),
  });

  const createCompanyErrors: { [key: string]: ErrorOption } = {
    [CreateCompanyResponseStatus.ReferenceAlreadyTaken]: {
      message: t`Company already registered with this reference`,
    },
    [CreateCompanyResponseStatus.CountryNotFound]: {
      message: t`Country not found`,
    },
    [CreateCompanyResponseStatus.SomethingWentWrong]: {
      message: t`Something went wrong`,
    },
    [CreateCompanyResponseStatus.CountryNotImplemented]: {
      message: t`Country not implemented`,
    },
    [CreateCompanyResponseStatus.ReferenceNotFound]: {
      message: t`No company found with this reference`,
    },
  };

  const submit = async ({ reference, country }: CreateCompanyData) => {
    try {
      const response = await createCompany({
        variables: {
          input: {
            reference,
            country,
          },
        },
      });

      if (
        response.data?.createCompany?.status ===
        CreateCompanyResponseStatus.Success
      ) {
        onSubmit && onSubmit(response.data?.createCompany?.company as Company);

        return;
      }

      setError(
        "reference",
        createCompanyErrors[
          response.data?.createCompany?.status ||
            CreateCompanyResponseStatus.SomethingWentWrong
        ]
      );
    } catch (exception) {
      console.error(exception);

      setError(
        "reference",
        createCompanyErrors[CreateCompanyResponseStatus.SomethingWentWrong]
      );
    }
  };

  const country = watch("country");

  return (
    <form onSubmit={handleSubmit(submit)}>
      <FormControl fullWidth sx={{ mt: 5 }} error={!!errors.country?.message}>
        <InputLabel id="add-company-country" error={!!errors.country?.message}>
          <Trans>Country</Trans>
        </InputLabel>

        <CountrySelect
          required
          fullWidth
          autoFocus
          labelId={"add-company-country"}
          type={"country"}
          autoComplete="country"
          displayEmpty
          disabled={loading}
          label={<Trans>Country</Trans>}
          {...registerField("country")}
        />

        {errors.country?.message && (
          <FormHelperText>{errors.country?.message}</FormHelperText>
        )}
      </FormControl>

      {!!country && (
        <>
          <FormControl
            fullWidth
            sx={{ mt: 5 }}
            error={!!errors.reference?.message}
          >
            <MissingCompanySelect
              required
              fullWidth
              country={country}
              disabled={loading}
              label={<Trans>Reference</Trans>}
              name="reference"
              control={control}
            />
            {errors.reference?.message && (
              <FormHelperText>{errors.reference?.message}</FormHelperText>
            )}
          </FormControl>
        </>
      )}

      <Box sx={{ display: "flex", mt: 10 }}>
        <Button
          onClick={onCancel}
          disabled={loading}
          variant="contained"
          sx={{ mr: "auto" }}
          color="inherit"
        >
          <Trans>Cancel</Trans>
        </Button>

        <Button type={"submit"} disabled={loading} variant="contained">
          <Trans>Add company</Trans>
        </Button>
      </Box>
    </form>
  );
};

export default CreateCompanyForm;
