import { css, Global, useTheme } from "@emotion/react";
import React from "react";

import "reactflow/dist/style.css";

export const GlobalStyles = () => {
  const theme = useTheme();

  return (
    <Global
      styles={css`
        body {
          background-color: ${theme.backgroundColor};
        }

        html,
        body,
        #root {
          height: 100%;
        }

        #root {
          display: flex;
          flex-direction: column;
        }
      `}
    />
  );
};
