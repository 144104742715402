import React from "react";
import { Outlet } from "react-router-dom";
import Navigation from "../utils/Navigation";
import { Box } from "@mui/material";

const DashboardLayout = () => {
  return (
    <>
      <Navigation />

      <Box sx={{ paddingTop: "65px", height: "100%" }}>
        <Outlet />
      </Box>
    </>
  );
};

export default DashboardLayout;
