import React from "react";
import { Outlet } from "react-router-dom";
import TranslationActivate from "../middleware/TranslationActivate";

const Preload = () => {
  return (
    <TranslationActivate>
      <Outlet />
    </TranslationActivate>
  );
};

export default Preload;
