import { useApolloClient } from "@apollo/client";
import { Permission, useCurrentUserQuery } from "../hooks.generated";
import { deleteCookie, getCookie } from "../utils/cookie";

export default function useCurrentUser() {
  const client = useApolloClient();
  const { data, loading, error } = useCurrentUserQuery({
    skip: !getCookie("token"),
  });

  const token = getCookie("token");
  const user = data?.me?.user;

  const hasPermission = (permissions: Permission[]) => {
    return permissions.some(
      (permission) => (user?.permissions || []).indexOf(permission) !== -1
    );
  };

  const hasAllPermission = (permissions: Permission[]) => {
    return permissions.every(
      (permission) => (user?.permissions || []).indexOf(permission) !== -1
    );
  };

  const logout = () => {
    deleteCookie("token");

    client.resetStore();
  };

  if (loading) {
    return {
      loading,
      hasPermission,
      hasAllPermission,
      logout,
    };
  }

  if (!data?.me || error || !token || !user) {
    logout();

    return {
      user: null,
      loading,
      hasPermission,
      hasAllPermission,
      logout,
    };
  }

  return {
    user,
    token,
    loading,
    hasPermission,
    hasAllPermission,
    logout,
  };
}
