import React from "react";
import {
  TrackedEventType,
  usePaginateTrackedEventsQuery,
} from "../../../hooks.generated";
import {
  Alert,
  Box,
  Chip,
  LinearProgress,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { Trans } from "@lingui/macro";
import { Email, Http } from "@mui/icons-material";
import Pagination from "../../utils/Pagination";
import { usePagination } from "../../../hooks/usePagination";
import { i18n } from "@lingui/core";
import { formatHostname } from "../../../utils/format";

interface PaginateTrackedEventsProps {
  trackedSessionId: string;
}

export function formatTrackedRequestPath(url?: string | null) {
  if (!url) {
    return null;
  }

  const fullUrl = new URL(url);

  return fullUrl.pathname + fullUrl.search + fullUrl.hash;
}

const PaginateTrackedEvents = ({
  trackedSessionId,
}: PaginateTrackedEventsProps) => {
  const { pagination, changePage } = usePagination({
    alias: "tr",
    limit: 30,
  });

  const {
    data: trackedEventsData,
    error: trackedEventsError,
    loading: trackedEventsLoading,
  } = usePaginateTrackedEventsQuery({
    variables: {
      trackedSessionId,
      pagination,
    },
  });

  if (trackedEventsError) {
    throw trackedEventsError;
  }

  return (
    <Box>
      {!trackedEventsLoading &&
        !trackedEventsData?.paginateTrackedEvents.trackedEvents?.data
          .length && (
          <Alert severity="info">
            <Trans>No tracked events found.</Trans>
          </Alert>
        )}

      {trackedEventsLoading && <LinearProgress />}

      <List
        sx={{
          width: "100%",
          position: "relative",
          overflow: "auto",
        }}
      >
        {trackedEventsData?.paginateTrackedEvents.trackedEvents?.data.map(
          (trackedRequest) => (
            <ListItem key={trackedRequest.id} dense>
              <ListItemButton>
                <ListItemIcon>
                  {trackedRequest.type === TrackedEventType.Request && <Http />}
                  {trackedRequest.type === TrackedEventType.Email && <Email />}
                </ListItemIcon>

                <ListItemText
                  primary={
                    trackedRequest.type === TrackedEventType.Request
                      ? formatTrackedRequestPath(trackedRequest.url)
                      : trackedRequest.emailAddress
                  }
                  secondary={formatHostname(trackedRequest.url)}
                />

                <Box>
                  <Chip
                    label={trackedRequest.type}
                    sx={{ mr: 2 }}
                    size="small"
                  />

                  {!!trackedRequest.referrer && (
                    <Chip
                      label={formatHostname(trackedRequest.referrer)}
                      sx={{ mr: 2 }}
                      size="small"
                    />
                  )}

                  <Chip
                    label={i18n.date(trackedRequest.createdAt, {
                      timeStyle: "medium",
                      dateStyle: "medium",
                    })}
                    sx={{ mr: 2 }}
                    size="small"
                  />

                  {!!trackedRequest.ipAddress && (
                    <Chip
                      label={trackedRequest.ipAddress?.address}
                      sx={{ mr: 2 }}
                      size="small"
                    />
                  )}
                </Box>
              </ListItemButton>
            </ListItem>
          )
        )}
      </List>

      <Pagination
        current={
          trackedEventsData?.paginateTrackedEvents.trackedEvents?.currentPage
        }
        total={trackedEventsData?.paginateTrackedEvents.trackedEvents?.total}
        limit={trackedEventsData?.paginateTrackedEvents.trackedEvents?.limit}
        onPageChange={(page) => changePage(page)}
      />
    </Box>
  );
};

export default PaginateTrackedEvents;
