import React, { ReactNode } from "react";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";

interface ModalProps {
  visible: boolean;
  title?: ReactNode;
  content?: ReactNode;
  onClose(): void;
}

const Modal = ({ visible, title, content, onClose }: ModalProps) => {
  if (!visible) {
    return null;
  }

  return (
    <Dialog open={visible} onClose={onClose} fullWidth={true}>
      <DialogTitle variant="h3">{title}</DialogTitle>

      <DialogContent>{content}</DialogContent>
    </Dialog>
  );
};

export default Modal;
