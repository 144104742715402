import React from "react";
import { ErrorOption, useForm } from "react-hook-form";
import { object, string } from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { t, Trans } from "@lingui/macro";
import { Box, Button, FormControl, FormHelperText } from "@mui/material";
import {
  Company,
  CompanyByIpAddressResponseStatus,
  CreateCompanyResponseStatus,
  useCompanyByIpAddressLazyQuery,
} from "../../hooks.generated";
import IpAddressField from "../utils/IpAddressField";

interface ResolveCompanyFormProps {
  onSelect(company: Company): void;
  onCancel?(): void;
}

interface ResolveCompanyData {
  address: string;
}

const resolveCompanyValidation = object({
  address: string()
    .required()
    .matches(
      new RegExp(
        "^((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?).){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$"
      ),
      {
        message: t`Invalid IP4 address`,
        excludeEmptyString: true,
      }
    ),
});

const ResolveCompanyForm = ({
  onSelect,
  onCancel,
}: ResolveCompanyFormProps) => {
  const [resolveCompany, { loading }] = useCompanyByIpAddressLazyQuery();

  const {
    handleSubmit,
    setError,
    control,
    formState: { errors },
  } = useForm<ResolveCompanyData>({
    resolver: yupResolver(resolveCompanyValidation),
  });

  const resolveCompanyErrors: { [key: string]: ErrorOption } = {
    [CompanyByIpAddressResponseStatus.CompanyNotFound]: {
      message: t`Company not found`,
    },
    [CompanyByIpAddressResponseStatus.IpAddressNotFound]: {
      message: t`IP address not found`,
    },
    [CompanyByIpAddressResponseStatus.SomethingWentWrong]: {
      message: t`Something went wrong`,
    },
  };

  const submit = async ({ address }: ResolveCompanyData) => {
    try {
      const response = await resolveCompany({
        variables: {
          address,
        },
      });

      if (
        response.data?.companyByIpAddress?.status ===
        CompanyByIpAddressResponseStatus.Success
      ) {
        onSelect &&
          onSelect(response.data?.companyByIpAddress?.company as Company);

        return;
      }

      setError(
        "address",
        resolveCompanyErrors[
          response.data?.companyByIpAddress?.status ||
            CreateCompanyResponseStatus.SomethingWentWrong
        ]
      );
    } catch (exception) {
      console.error(exception);

      setError(
        "address",
        resolveCompanyErrors[CreateCompanyResponseStatus.SomethingWentWrong]
      );
    }
  };

  return (
    <form onSubmit={handleSubmit(submit)}>
      <FormControl fullWidth sx={{ mt: 5 }} error={!!errors.address?.message}>
        <IpAddressField
          required
          fullWidth
          autoFocus
          name="address"
          autoComplete="ipaddress"
          disabled={loading}
          error={!!errors.address?.message}
          label={<Trans>Address</Trans>}
          control={control}
        />

        {errors.address?.message && (
          <FormHelperText>{errors.address?.message}</FormHelperText>
        )}
      </FormControl>

      <Box sx={{ display: "flex", mt: 10 }}>
        <Button
          onClick={onCancel}
          disabled={loading}
          variant="contained"
          sx={{ mr: "auto" }}
          color="inherit"
        >
          <Trans>Cancel</Trans>
        </Button>

        <Button type={"submit"} disabled={loading} variant="contained">
          <Trans>Find company</Trans>
        </Button>
      </Box>
    </form>
  );
};

export default ResolveCompanyForm;
