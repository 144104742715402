import { Communication, CommunicationType } from "../hooks.generated";

export function getCommunicationLink(communication: Communication): string {
  switch (communication.type) {
    case CommunicationType.Email:
      return `mailto:${communication.value}`;
    case CommunicationType.Phone:
    case CommunicationType.Mobile:
      return `tel:${communication.value}`;
  }

  return "";
}
