import React from "react";
import { Permission } from "../../../hooks.generated";
import { Box, Grid } from "@mui/material";
import AllCaptchas from "../../elements/AllCaptchas";

export const captchaPagePermissions = [Permission.Captcha];

const CaptchaPage = () => {
  return (
    <>
      <Box>
        <Box>
          <Grid container>
            <Grid item xs={12} padding={3}>
              <Box padding={2}>
                <AllCaptchas />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default CaptchaPage;
