import {
  Autocomplete,
  CircularProgress,
  TextField,
  TextFieldProps,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import {
  MissingCompany,
  useSearchMissingCompaniesLazyQuery,
} from "../../hooks.generated";
import { debounce } from "@mui/material/utils";
import { Trans } from "@lingui/macro";
import { Control, Controller, FieldValues } from "react-hook-form";

type MissingCompanySelectProps<T extends FieldValues = any> = {
  country: string;
  name: string;
  control: Control<T>;
} & TextFieldProps;

const MissingCompanySelect = ({
  country,
  control,
  ...props
}: MissingCompanySelectProps) => {
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState<readonly MissingCompany[]>([]);

  const [inputValue, setInputValue] = React.useState("");
  const [value, setValue] = React.useState<MissingCompany | null>(null);

  const [load] = useSearchMissingCompaniesLazyQuery();
  const [loading, setLoading] = useState(false);

  const fetch = useMemo(
    () =>
      debounce(
        async (
          search: string,
          callback: (companies?: readonly MissingCompany[]) => void
        ) => {
          setLoading(true);
          const response = await load({
            variables: {
              search,
              country,
            },
          });

          setLoading(false);

          callback(response.data?.searchMissingCompanies.companies || []);
        },
        400
      ),
    [country, load]
  );

  useEffect(() => {
    let active = true;

    if (inputValue === "") {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetch(inputValue, (results?: readonly MissingCompany[]) => {
      if (active) {
        let newOptions: readonly MissingCompany[] = [];

        if (value) {
          newOptions = [value];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);

  return (
    <Controller
      control={control}
      name={props.name}
      render={({ field: { onChange, ...controllerProps } }) => (
        <Autocomplete
          {...controllerProps}
          open={open}
          onOpen={() => {
            setOpen(true);
          }}
          onClose={() => {
            setOpen(false);
          }}
          isOptionEqualToValue={(option: MissingCompany, value) =>
            option.name === value.name
          }
          getOptionLabel={(option: MissingCompany) => String(option.name)}
          options={options}
          loading={loading}
          filterOptions={(x) => x}
          renderInput={(params) => (
            <TextField
              {...props}
              {...params}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {loading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
            />
          )}
          includeInputInList
          filterSelectedOptions
          value={value}
          noOptionsText={<Trans>No companies found.</Trans>}
          onChange={(event: any, newValue: MissingCompany | null) => {
            setOptions(newValue ? [newValue, ...options] : options);
            setValue(newValue);

            onChange(newValue?.reference);
          }}
          onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
          }}
        />
      )}
    />
  );
};

export default MissingCompanySelect;
