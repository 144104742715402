import { Trans } from "@lingui/macro";
import { Box, MenuItem, Select, SelectProps } from "@mui/material";
import React, { ReactElement } from "react";
import ReactCountryFlag from "react-country-flag";

interface Country {
  name: ReactElement;
  icon: string;
  iso: string;
}

export const countries: Country[] = [
  {
    name: <Trans>Netherlands</Trans>,
    icon: "nl",
    iso: "nl",
  },
];

const CountrySelect = (props: SelectProps) => {
  return (
    <Select
      {...props}
      renderValue={(value) => {
        const country = countries.find((country) => country.iso === value);

        if (!country) {
          return null;
        }

        return (
          <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
            <>
              <ReactCountryFlag countryCode={country.icon} svg />
              {country.name}
            </>
          </Box>
        );
      }}
    >
      {countries.map((country) => (
        <MenuItem key={country.iso} value={country.iso}>
          {country.name}
        </MenuItem>
      ))}
    </Select>
  );
};

export default CountrySelect;
