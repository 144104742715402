import { useSearchParams } from "react-router-dom";
import { PaginationInput } from "../hooks.generated";

interface UsePaginationParams {
  limit?: Number;
  alias?: string;
}

interface UsePagination {
  pagination: PaginationInput;
  changePage(page: number): void;
}

export function usePagination({
  limit,
  alias,
}: UsePaginationParams): UsePagination {
  const [search, setSearchParams] = useSearchParams();
  const paginationAlias = alias && alias.length ? `${alias}.` : "";

  return {
    changePage(page) {
      search.set(paginationAlias + "page", String(page));

      setSearchParams(search);
    },

    pagination: {
      page: Number(search.get(paginationAlias + "page") || 1),
      limit: Number(search.get(paginationAlias + "limit") || limit || 10),
    },
  };
}
