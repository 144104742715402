import React, { useState } from "react";
import useCurrentUser from "../../hooks/useCurrentUser";
import {
  AppBar,
  Box,
  Button,
  IconButton,
  InputBase,
  Menu,
  MenuItem,
  Popover,
  Toolbar,
  Typography,
  alpha,
} from "@mui/material";
import { AccountCircle, Add, Dns, PlaylistPlay } from "@mui/icons-material";
import { Trans, t } from "@lingui/macro";
import styled from "@emotion/styled";
import SearchIcon from "@mui/icons-material/Search";
import { useMatch, useSearchParams } from "react-router-dom";
import LanguagePicker from "./LanguagePicker";
import Modal from "./Modal";
import CreateCompanyForm from "../forms/CreateCompanyForm";
import { Permission } from "../../hooks.generated";
import LocaleLink from "./LocaleLink";
import { useI18n } from "../../hooks/useI18n";
import ResolveCompanyForm from "../forms/ResolveCompanyForm";
import PaginateTrackers from "../elements/trackers/PaginateTrackers";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: "5px",
  backgroundColor: alpha("#fff", 0.15),

  "&:hover": {
    backgroundColor: alpha("#fff", 0.25),
  },

  marginRight: "5px",
  marginLeft: 0,
  width: "auto",
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: "0 10px",
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const SearchInput = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: "10px",
    paddingLeft: "40px",
    width: "100%",
  },
}));

const Sidebar = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [companyCreateModalVisible, showCompanyCreateModal] = useState(false);
  const [companyResolveModalVisible, showCompanyResolveModal] = useState(false);
  const [selectTrackerPopoverVisible, showSelectTrackerPopover] =
    useState(false);
  const [accountMenuVisible, showAccountMenu] = useState(false);

  const [searchParameters, setSearchParams] = useSearchParams();
  const { user, logout, hasPermission } = useCurrentUser();
  const { locale } = useI18n();

  const isTrackers = useMatch(`/${locale}/dashboard/trackers`);
  const isCompanies = useMatch(`/${locale}/dashboard/companies`);

  return (
    <>
      <AppBar position="fixed">
        <Toolbar>
          {isCompanies && hasPermission([Permission.CompanyIndex]) && (
            <Search>
              <SearchIconWrapper>
                <SearchIcon />
              </SearchIconWrapper>
              <SearchInput
                value={searchParameters.get("search") || ""}
                placeholder={t`Search...`}
                onChange={(event) => {
                  searchParameters.set("search", event.currentTarget.value);

                  if (searchParameters.has("page")) {
                    searchParameters.set("page", "1");
                  }

                  setSearchParams(searchParameters);
                }}
              />
            </Search>
          )}

          {isCompanies && hasPermission([Permission.CompanyResolve]) && (
            <IconButton
              size="large"
              edge="end"
              onClick={() => {
                showCompanyResolveModal(true);
              }}
              color="inherit"
            >
              <Dns />
            </IconButton>
          )}

          {isCompanies && hasPermission([Permission.CompanyCreate]) && (
            <IconButton
              size="large"
              edge="end"
              onClick={() => {
                showCompanyCreateModal(true);
              }}
              color="inherit"
            >
              <Add />
            </IconButton>
          )}

          {isTrackers && hasPermission([Permission.TrackerIndex]) && (
            <>
              <IconButton
                size="large"
                edge="end"
                onClick={(event) => {
                  setAnchorEl(event.currentTarget);
                  showSelectTrackerPopover(true);
                }}
                color="inherit"
              >
                <PlaylistPlay />
              </IconButton>

              <Popover
                open={selectTrackerPopoverVisible}
                anchorEl={anchorEl}
                onClose={() => showSelectTrackerPopover(false)}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
              >
                <PaginateTrackers
                  onChange={() => showSelectTrackerPopover(false)}
                />
              </Popover>
            </>
          )}

          <Box sx={{ flexGrow: 1 }} />

          <Box sx={{ mr: 5 }}>
            {hasPermission([Permission.CompanyIndex]) && (
              <LocaleLink to={"/dashboard/companies"}>
                <Button sx={{ color: "#fff" }}>
                  <Trans>Companies</Trans>
                </Button>
              </LocaleLink>
            )}

            {hasPermission([Permission.TrackerIndex]) && (
              <LocaleLink to={"/dashboard/trackers"}>
                <Button sx={{ color: "#fff" }}>
                  <Trans>Trackers</Trans>
                </Button>
              </LocaleLink>
            )}

            {hasPermission([Permission.Captcha]) && (
              <LocaleLink to={"/dashboard/captcha"}>
                <Button sx={{ color: "#fff" }}>
                  <Trans>Captcha</Trans>
                </Button>
              </LocaleLink>
            )}

            {hasPermission([Permission.SystemStatus]) && (
              <a
                href={"https://l337direct.grafana.net/"}
                target="_blank"
                rel="noreferrer"
              >
                <Button sx={{ color: "#fff" }}>
                  <Trans>System status</Trans>
                </Button>
              </a>
            )}
          </Box>

          <Box sx={{ mr: 5 }}>
            <LanguagePicker
              sx={{
                color: "#fff",
                ".MuiOutlinedInput-notchedOutline": {
                  borderColor: alpha("#fff", 0.15),
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: alpha("#fff", 0.15),
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: alpha("#fff", 0.15),
                },
                ".MuiSvgIcon-root ": {
                  fill: "#fff !important",
                },
              }}
            />
          </Box>

          <Typography
            variant="h4"
            noWrap
            component="div"
            sx={{ display: { xs: "none", sm: "block" }, cursor: "pointer" }}
            onClick={(event) => {
              setAnchorEl(event.currentTarget);
              showAccountMenu(true);
            }}
          >
            {user?.name}
          </Typography>

          <IconButton
            size="large"
            edge="end"
            onClick={(event) => {
              setAnchorEl(event.currentTarget);
              showAccountMenu(true);
            }}
            color="inherit"
          >
            <AccountCircle />
          </IconButton>
        </Toolbar>
      </AppBar>

      {accountMenuVisible && (
        <Menu
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          id={"user-menu"}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={accountMenuVisible}
          onClose={() => showAccountMenu(false)}
        >
          <MenuItem onClick={() => logout()}>
            <Trans>Logout</Trans>
          </MenuItem>
        </Menu>
      )}

      <Modal
        visible={companyCreateModalVisible}
        onClose={() => {
          showCompanyCreateModal(false);
        }}
        title={t`Create a company`}
        content={
          <CreateCompanyForm
            onSubmit={(company) => {
              showCompanyCreateModal(false);

              searchParameters.set("company", company.id);
              searchParameters.set("search", company.names.join(', '));

              if (searchParameters.has("page")) {
                searchParameters.set("page", "1");
              }

              setSearchParams(searchParameters);
            }}
            onCancel={() => {
              showCompanyCreateModal(false);
            }}
          />
        }
      />

      <Modal
        visible={companyResolveModalVisible}
        onClose={() => {
          showCompanyResolveModal(false);
        }}
        title={t`Find a company by IP address`}
        content={
          <ResolveCompanyForm
            onSelect={(company) => {
              showCompanyResolveModal(false);

              searchParameters.set("company", company.id);
              searchParameters.set("search", company.names.join(', '));

              if (searchParameters.has("page")) {
                searchParameters.set("page", "1");
              }

              setSearchParams(searchParameters);
            }}
            onCancel={() => {
              showCompanyResolveModal(false);
            }}
          />
        }
      />
    </>
  );
};

export default Sidebar;
