import { i18n } from "@lingui/core";
import { en, nl } from "make-plural/plurals";

export const locales = {
  en: "English",
  nl: "Nederlands",
};
export const defaultLocale = "en";

i18n.loadLocaleData({
  en: { plurals: en },
  nl: { plurals: nl },
});

export async function dynamicActivate(locale: string) {
  const { messages } = await import(`./locales/${locale}/messages`);
  i18n.load(locale, messages);
  i18n.activate(locale);
}
