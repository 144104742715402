import React from "react";
import { companiesPagePermissions } from "./dashboard/CompaniesPage";
import { Navigate } from "react-router-dom";
import useCurrentUser from "../../hooks/useCurrentUser";

const DashboardRedirect = () => {
  const { hasPermission } = useCurrentUser();

  if (hasPermission(companiesPagePermissions)) {
    return <Navigate to={"companies"} />;
  }

  return <Navigate to={"trackers"} />;
};

export default DashboardRedirect;
