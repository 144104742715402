import React from "react";
import { Link, LinkProps } from "react-router-dom";
import { useI18n } from "../../hooks/useI18n";

interface OwnProps {
  locale?: string;
}

type LocaleLinkProps = LinkProps & OwnProps;

const LocaleLink = ({ locale, ...args }: LocaleLinkProps) => {
  const { getLink } = useI18n();

  return <Link {...args} to={getLink(args.to, locale)} />;
};

export default LocaleLink;
