import { Trans } from "@lingui/macro";
import { Box, MenuItem, Select, SelectProps } from "@mui/material";
import React, { ReactElement } from "react";
import { useI18n } from "../../hooks/useI18n";
import ReactCountryFlag from "react-country-flag";

interface Locale {
  name: ReactElement;
  icon: string;
}

export const locales: { [key: string]: Locale } = {
  en: {
    name: <Trans>English</Trans>,
    icon: "gb",
  },
  nl: {
    name: <Trans>Dutch</Trans>,
    icon: "nl",
  },
};

const LanguagePicker = (props: SelectProps) => {
  const { locale, navigate } = useI18n();

  return (
    <Select
      {...props}
      value={locale}
      size={"small"}
      onChange={(event) => {
        const newLocale = String(event.target.value);

        navigate(
          window.location.pathname.substring(
            3,
            window.location.pathname.length
          ) + window.location.search,
          undefined,
          newLocale
        );
      }}
      renderValue={(value) => {
        return (
          <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
            <>
              <ReactCountryFlag countryCode={locales[String(value)].icon} svg />
              {locales[String(value)].name}
            </>
          </Box>
        );
      }}
    >
      {Object.entries(locales).map(([locale]) => (
        <MenuItem key={locale} value={locale}>
          {locales[locale].name}
        </MenuItem>
      ))}
    </Select>
  );
};

export default LanguagePicker;
