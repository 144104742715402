import { Trans } from "@lingui/macro";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useI18n } from "../../hooks/useI18n";
import { dynamicActivate } from "../../i18n";
import LoginForm from "../forms/LoginForm";
import AuthLayout from "../layouts/AuthLayout";

const LoginPage = () => {
  const { locale } = useParams();
  const { navigate } = useI18n();

  useEffect(() => {
    dynamicActivate(String(locale));
  }, [locale]);

  return (
    <AuthLayout title={<Trans>Login</Trans>}>
      <LoginForm
        onSubmit={() => {
          navigate("/dashboard");
        }}
      />
    </AuthLayout>
  );
};

export default LoginPage;
