import React, { PropsWithChildren } from "react";
import { Navigate } from "react-router-dom";
import useCurrentUser from "../../hooks/useCurrentUser";
import { useI18n } from "../../hooks/useI18n";
import { Permission } from "../../hooks.generated";
import { LinearProgress } from "@mui/material";

interface AuthProps {
  permissions?: Permission[];
}

const Auth = ({ children, permissions }: PropsWithChildren<AuthProps>) => {
  const { getLink } = useI18n();
  const { user, loading, hasAllPermission } = useCurrentUser();

  if (loading) {
    return <LinearProgress />;
  }

  if (!user) {
    return <Navigate to={getLink("/login")} replace />;
  }

  if (!hasAllPermission(permissions || [])) {
    throw new Error("No permissions");
  }

  return <>{children}</>;
};

export default Auth;
