import React, { useEffect, useState } from "react";
import {
  useAllCaptchasQuery,
  useSaveCaptchaResultMutation,
} from "../../hooks.generated";
import {
  Alert,
  Box,
  Button,
  LinearProgress,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Paper,
  TextField,
} from "@mui/material";
import { Trans } from "@lingui/macro";

interface CaptchaResult {
  [key: string]: string;
}

const AllCaptchas = () => {
  const {
    data: allCaptchasData,
    error: allCaptchasError,
    loading: allCaptchasLoading,
  } = useAllCaptchasQuery();
  const [saveCaptchaResult] = useSaveCaptchaResultMutation();

  const [captchaResults, setCaptchaResults] = useState<CaptchaResult>({});

  if (allCaptchasError) {
    throw allCaptchasError;
  }

  useEffect(() => {
    setCaptchaResults(
      (allCaptchasData?.allCaptchas?.captchas || []).reduce<CaptchaResult>(
        (captchaResultsData, captcha) => {
          captchaResultsData[captcha.id] = captcha.result || "";
          return captchaResultsData;
        },
        {}
      )
    );
  }, [allCaptchasData]);

  return (
    <Box component={Paper} padding={2}>
      {!allCaptchasLoading &&
        !allCaptchasData?.allCaptchas.captchas?.length && (
          <Alert severity="info">
            <Trans>No captcha's found.</Trans>
          </Alert>
        )}

      {allCaptchasLoading && <LinearProgress />}

      <List
        sx={{
          width: "100%",
          position: "relative",
          overflow: "auto",
        }}
      >
        {allCaptchasData?.allCaptchas.captchas.map((captcha, index) => (
          <ListItem key={index} dense>
            <ListItemButton>
              <ListItemText
                primary={captcha.type}
                secondary={captcha.hostname}
                sx={{ mr: 5 }}
              />

              <img src={captcha.image} alt={"Captcha"} />

              <TextField
                sx={{ width: "150px", marginLeft: "15px" }}
                margin="normal"
                required
                fullWidth
                type={"email"}
                autoComplete="email"
                autoFocus
                value={captchaResults[captcha.id]}
                onChange={(event) => {
                  setCaptchaResults({
                    ...captchaResults,
                    [captcha.id]: event.target.value,
                  });
                }}
              />

              <Button
                color="primary"
                sx={{ marginLeft: "5px" }}
                variant="contained"
                onClick={() => {
                  saveCaptchaResult({
                    variables: {
                      captchaId: captcha.id,
                      result: captchaResults[captcha.id],
                    },
                  });
                }}
              >
                <Trans>Save</Trans>
              </Button>
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

export default AllCaptchas;
