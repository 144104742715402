import { createBrowserRouter, Navigate } from "react-router-dom";
import { defaultLocale } from "./i18n";
import LoginPage from "./components/pages/LoginPage";
import CompaniesPage, {
  companiesPagePermissions,
} from "./components/pages/dashboard/CompaniesPage";
import Preload from "./components/layouts/Preload";
import DashboardLayout from "./components/layouts/DashboardLayout";
import Auth from "./components/middleware/Auth";
import Guest from "./components/middleware/Guest";
import TrackersPage, {
  trackersPagePermissions,
} from "./components/pages/dashboard/TrackersPage";
import DashboardRedirect from "./components/pages/DashboardRedirect";
import CaptchaPage, {
  captchaPagePermissions,
} from "./components/pages/dashboard/CaptchaPage";

const router = createBrowserRouter([
  {
    path: "/:locale",
    element: <Preload />,
    children: [
      {
        path: "",
        element: <Navigate to={"dashboard"} />,
      },
      {
        path: "login",
        element: (
          <Guest>
            <LoginPage />
          </Guest>
        ),
      },
      {
        path: "dashboard",
        element: <DashboardLayout />,
        children: [
          {
            path: "",
            element: <DashboardRedirect />,
          },
          {
            path: "companies",
            element: (
              <Auth permissions={companiesPagePermissions}>
                <CompaniesPage />
              </Auth>
            ),
          },
          {
            path: "trackers",
            element: (
              <Auth permissions={trackersPagePermissions}>
                <TrackersPage />
              </Auth>
            ),
          },
          {
            path: "captcha",
            element: (
              <Auth permissions={captchaPagePermissions}>
                <CaptchaPage />
              </Auth>
            ),
          },
        ],
      },
    ],
  },
  {
    path: "*",
    element: <Navigate to={"/" + defaultLocale + "/dashboard"} />,
  },
]);

export default router;
