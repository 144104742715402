import React, { PropsWithChildren, useEffect } from "react";
import { useParams } from "react-router-dom";
import { dynamicActivate } from "../../i18n";

const TranslationActivate = ({ children }: PropsWithChildren<{}>) => {
  const { locale } = useParams();

  useEffect(() => {
    dynamicActivate(String(locale));
  }, [locale]);

  return <>{children}</>;
};

export default TranslationActivate;
