import { Box, Container, Paper, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import React, { PropsWithChildren, ReactNode } from "react";
import LanguagePicker from "../utils/LanguagePicker";

interface AuthLayoutProps {
  title: ReactNode;
}

const AuthLayout = ({
  children,
  title,
}: PropsWithChildren<AuthLayoutProps>) => {
  return (
    <Container component={"main"}>
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Grid
          container
          component={Paper}
          sx={{
            maxWidth: {
              md: "400px",
              sm: "80%",
            },
          }}
        >
          <Grid item xs={12} padding={5}>
            <Typography component="h1" variant="h1" sx={{ mt: 0, mb: 3 }}>
              {title}
            </Typography>

            {children}

            <Box textAlign={"right"} mt={5}>
              <LanguagePicker />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default AuthLayout;
