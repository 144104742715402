import React from "react";
import {
  PaginateTrackedSessionsResponseStatus,
  usePaginateTrackedSessionsQuery,
} from "../../../hooks.generated";
import {
  Alert,
  Box,
  Chip,
  LinearProgress,
  List,
  ListItem,
  ListItemButton,
  Paper,
} from "@mui/material";
import { Trans } from "@lingui/macro";
import Pagination from "../../utils/Pagination";
import { usePagination } from "../../../hooks/usePagination";
import { useSearchParams } from "react-router-dom";

interface PaginateTrackedSessionsProps {
  trackerId: string;
}

const PaginateTrackedSessions = ({
  trackerId,
}: PaginateTrackedSessionsProps) => {
  const [searchParameters, setSearchParams] = useSearchParams();
  const { pagination, changePage } = usePagination({
    alias: "ts",
    limit: 200,
  });

  const {
    data: trackedSessionsData,
    error: trackedSessionsError,
    loading: trackedSessionsLoading,
  } = usePaginateTrackedSessionsQuery({
    variables: {
      trackerId,
      pagination,
    },
  });

  if (trackedSessionsError) {
    throw trackedSessionsError;
  }

  const currentTrackedSessionId = searchParameters.get("tracked_session");

  if (
    !trackedSessionsLoading &&
    trackedSessionsData?.paginateTrackedSessions.status ===
      PaginateTrackedSessionsResponseStatus.TrackerNotFound
  ) {
    searchParameters.delete("tracker");
    searchParameters.delete("tracked_session");

    setSearchParams(searchParameters);
  }

  return (
    <Box
      component={Paper}
      padding={2}
      sx={{ display: "flex", flexDirection: "column", height: "100%" }}
    >
      {!trackedSessionsLoading &&
        !trackedSessionsData?.paginateTrackedSessions.trackedSessions?.data
          .length && (
          <Alert severity="info">
            <Trans>No tracked sessions found.</Trans>
          </Alert>
        )}

      {trackedSessionsLoading && <LinearProgress />}

      <List
        sx={{
          width: "100%",
          position: "relative",
          overflow: "auto",
        }}
      >
        {trackedSessionsData?.paginateTrackedSessions.trackedSessions?.data.map(
          (trackedSession) => {
            return (
              <ListItem key={trackedSession.id} dense disablePadding>
                <ListItemButton
                  sx={{ flexDirection: "column", alignItems: "flex-start" }}
                  selected={trackedSession.id === currentTrackedSessionId}
                  onClick={() => {
                    searchParameters.set("tracked_session", trackedSession.id);
                    searchParameters.delete("tr.page");
                    setSearchParams(searchParameters);
                  }}
                >
                  <Box sx={{ width: "100%" }}>
                    {(trackedSession.resolvedCompanies || []).map(
                      (resolvedCompany) => {
                        return (
                          <Chip
                            key={resolvedCompany.company.id}
                            label={resolvedCompany.company.names.join(', ')}
                            sx={{ mr: 1 }}
                            size="small"
                          />
                        );
                      }
                    )}

                    {(trackedSession.domains || []).map((domain) => {
                      return (
                        <Chip
                          key={domain.id}
                          label={domain.fqdn}
                          sx={{ mr: 1 }}
                          size="small"
                        />
                      );
                    })}

                    {(trackedSession.emails || []).map((email) => {
                      return (
                        <Chip
                          key={email}
                          label={email}
                          sx={{ mr: 1 }}
                          size="small"
                        />
                      );
                    })}
                  </Box>
                </ListItemButton>
              </ListItem>
            );
          }
        )}
      </List>

      <Pagination
        current={
          trackedSessionsData?.paginateTrackedSessions.trackedSessions
            ?.currentPage
        }
        total={
          trackedSessionsData?.paginateTrackedSessions.trackedSessions?.total
        }
        limit={
          trackedSessionsData?.paginateTrackedSessions.trackedSessions?.limit
        }
        onPageChange={(page) => changePage(page)}
      />
    </Box>
  );
};

export default PaginateTrackedSessions;
