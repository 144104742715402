import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
};

export type Address = {
  __typename?: 'Address';
  country?: Maybe<Country>;
  houseNumber?: Maybe<Scalars['String']>;
  houseNumberAddition?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  place?: Maybe<Scalars['String']>;
  reliability?: Maybe<Scalars['Int']>;
  source?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  type?: Maybe<AddressType>;
  zipcode?: Maybe<Scalars['String']>;
};

export enum AddressType {
  Correspondence = 'CORRESPONDENCE',
  Unknown = 'UNKNOWN',
  Visit = 'VISIT'
}

export type AllCaptchasResponse = {
  __typename?: 'AllCaptchasResponse';
  captchas: Array<Captcha>;
  status: AllCaptchasResponseStatus;
};

export enum AllCaptchasResponseStatus {
  SomethingWentWrong = 'SOMETHING_WENT_WRONG',
  Success = 'SUCCESS'
}

export type Captcha = {
  __typename?: 'Captcha';
  hostname: Scalars['String'];
  id: Scalars['ID'];
  image: Scalars['String'];
  result?: Maybe<Scalars['String']>;
  type: Scalars['String'];
};

export type Communication = {
  __typename?: 'Communication';
  id: Scalars['ID'];
  reliability?: Maybe<Scalars['Int']>;
  source?: Maybe<Scalars['String']>;
  type?: Maybe<CommunicationType>;
  value?: Maybe<Scalars['String']>;
};

export enum CommunicationType {
  Email = 'EMAIL',
  Mobile = 'MOBILE',
  Phone = 'PHONE'
}

export type Company = {
  __typename?: 'Company';
  addresses?: Maybe<Array<Address>>;
  communications?: Maybe<Array<Communication>>;
  country?: Maybe<Country>;
  id: Scalars['ID'];
  logo?: Maybe<Scalars['String']>;
  names: Array<Scalars['String']>;
  reference: Scalars['String'];
  relatedDomains?: Maybe<Array<RelatedDomain>>;
  relatedIpAddresses?: Maybe<Array<RelatedIpAddress>>;
  tags?: Maybe<Array<Tag>>;
};

export type CompanyByIdResponse = {
  __typename?: 'CompanyByIdResponse';
  company?: Maybe<Company>;
  status: CompanyByIdResponseStatus;
};

export enum CompanyByIdResponseStatus {
  NotFound = 'NOT_FOUND',
  SomethingWentWrong = 'SOMETHING_WENT_WRONG',
  Success = 'SUCCESS'
}

export type CompanyByIpAddressResponse = {
  __typename?: 'CompanyByIpAddressResponse';
  company?: Maybe<Company>;
  score?: Maybe<Scalars['Int']>;
  status: CompanyByIpAddressResponseStatus;
};

export enum CompanyByIpAddressResponseStatus {
  CompanyNotFound = 'COMPANY_NOT_FOUND',
  InvalidIp_4Address = 'INVALID_IP_4_ADDRESS',
  IpAddressNotFound = 'IP_ADDRESS_NOT_FOUND',
  SomethingWentWrong = 'SOMETHING_WENT_WRONG',
  Success = 'SUCCESS'
}

export type CompanyPagination = {
  __typename?: 'CompanyPagination';
  currentPage: Scalars['Int'];
  data: Array<Company>;
  limit: Scalars['Int'];
  total: Scalars['Int'];
};

export type Country = {
  __typename?: 'Country';
  id: Scalars['ID'];
  iso: Scalars['String'];
  name: Scalars['String'];
};

export type CreateCompanyInput = {
  country: Scalars['String'];
  reference: Scalars['String'];
};

export type CreateCompanyResponse = {
  __typename?: 'CreateCompanyResponse';
  company?: Maybe<Company>;
  status: CreateCompanyResponseStatus;
};

export enum CreateCompanyResponseStatus {
  CountryNotFound = 'COUNTRY_NOT_FOUND',
  CountryNotImplemented = 'COUNTRY_NOT_IMPLEMENTED',
  ReferenceAlreadyTaken = 'REFERENCE_ALREADY_TAKEN',
  ReferenceNotFound = 'REFERENCE_NOT_FOUND',
  SomethingWentWrong = 'SOMETHING_WENT_WRONG',
  Success = 'SUCCESS'
}

export type Domain = {
  __typename?: 'Domain';
  fqdn: Scalars['String'];
  id: Scalars['ID'];
  relatedIpAddresses?: Maybe<Array<RelatedIpAddress>>;
};

export enum EnrichmentSource {
  BedrijvenmonitorInfo = 'BEDRIJVENMONITOR_INFO',
  Dig = 'DIG',
  GooglePlaces = 'GOOGLE_PLACES',
  GoogleSearch = 'GOOGLE_SEARCH',
  IpApi = 'IP_API',
  Logo = 'LOGO',
  ReverseIpAddress = 'REVERSE_IP_ADDRESS',
  Ripe = 'RIPE',
  SslCertificate = 'SSL_CERTIFICATE',
  SubDomain = 'SUB_DOMAIN',
  Tld = 'TLD',
  Unknown = 'UNKNOWN',
  Whois = 'WHOIS'
}

export enum FilteringGroupOperator {
  And = 'AND',
  Or = 'OR'
}

export type IpAddress = {
  __typename?: 'IpAddress';
  address: Scalars['String'];
  addresses?: Maybe<Array<Address>>;
  id: Scalars['ID'];
};

export type LoginInput = {
  email: Scalars['String'];
  password: Scalars['String'];
};

export type LoginResponse = {
  __typename?: 'LoginResponse';
  status: LoginResponseStatus;
  token?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
};

export enum LoginResponseStatus {
  InvalidCredentials = 'INVALID_CREDENTIALS',
  SomethingWentWrong = 'SOMETHING_WENT_WRONG',
  Success = 'SUCCESS'
}

export type MeResponse = {
  __typename?: 'MeResponse';
  status: MeResponseStatus;
  user?: Maybe<User>;
};

export enum MeResponseStatus {
  SomethingWentWrong = 'SOMETHING_WENT_WRONG',
  Success = 'SUCCESS'
}

export type MissingCompany = {
  __typename?: 'MissingCompany';
  address?: Maybe<Address>;
  name?: Maybe<Scalars['String']>;
  reference?: Maybe<Scalars['String']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  createCompany: CreateCompanyResponse;
  login: LoginResponse;
  prioritizeCompanyEnrichers: PrioritizeCompanyEnrichersResponse;
  saveCaptchaResult: SaveCaptchaResultResponse;
  saveTag: SaveTagResponse;
  saveTracker: SaveTrackerResponse;
  saveUser: SaveUserResponse;
  setupUser: SetupUserResponse;
  trackEvent: TrackEventResponse;
};


export type MutationCreateCompanyArgs = {
  input: CreateCompanyInput;
};


export type MutationLoginArgs = {
  input: LoginInput;
};


export type MutationPrioritizeCompanyEnrichersArgs = {
  companyId: Scalars['ID'];
};


export type MutationSaveCaptchaResultArgs = {
  captchaId: Scalars['ID'];
  result: Scalars['String'];
};


export type MutationSaveTagArgs = {
  input: SaveTagInput;
  tagId?: InputMaybe<Scalars['ID']>;
};


export type MutationSaveTrackerArgs = {
  input: SaveTrackerInput;
  trackerId?: InputMaybe<Scalars['ID']>;
};


export type MutationSaveUserArgs = {
  input: SaveUserInput;
  userId?: InputMaybe<Scalars['ID']>;
};


export type MutationSetupUserArgs = {
  input: SetupUserInput;
};


export type MutationTrackEventArgs = {
  input: TrackEventInput;
  session?: InputMaybe<Scalars['String']>;
  trackerId: Scalars['ID'];
};

export type PaginateCompaniesInput = {
  pagination?: InputMaybe<PaginationInput>;
  search?: InputMaybe<Scalars['String']>;
};

export type PaginateCompaniesResponse = {
  __typename?: 'PaginateCompaniesResponse';
  companies?: Maybe<CompanyPagination>;
  status: PaginateCompaniesResponseStatus;
};

export enum PaginateCompaniesResponseStatus {
  SomethingWentWrong = 'SOMETHING_WENT_WRONG',
  Success = 'SUCCESS'
}

export type PaginateRelatedTrackedSessionsInput = {
  pagination?: InputMaybe<PaginationInput>;
};

export type PaginateTagsInput = {
  filtering?: InputMaybe<TagFilterGroup>;
  pagination?: InputMaybe<PaginationInput>;
};

export type PaginateTagsResponse = {
  __typename?: 'PaginateTagsResponse';
  status: PaginateTagsResponseStatus;
  tags?: Maybe<TagPagination>;
};

export enum PaginateTagsResponseStatus {
  SomethingWentWrong = 'SOMETHING_WENT_WRONG',
  Success = 'SUCCESS'
}

export type PaginateTrackedEventsInput = {
  filtering?: InputMaybe<TrackedEventFilterGroup>;
  pagination?: InputMaybe<PaginationInput>;
};

export type PaginateTrackedEventsResponse = {
  __typename?: 'PaginateTrackedEventsResponse';
  status: PaginateTrackedEventsResponseStatus;
  trackedEvents?: Maybe<TrackedEventPagination>;
};

export enum PaginateTrackedEventsResponseStatus {
  SomethingWentWrong = 'SOMETHING_WENT_WRONG',
  Success = 'SUCCESS',
  TrackerNotFound = 'TRACKER_NOT_FOUND'
}

export type PaginateTrackedSessionsInput = {
  filtering?: InputMaybe<TrackedSessionFilterGroup>;
  pagination?: InputMaybe<PaginationInput>;
};

export type PaginateTrackedSessionsResponse = {
  __typename?: 'PaginateTrackedSessionsResponse';
  status: PaginateTrackedSessionsResponseStatus;
  trackedSessions?: Maybe<TrackedSessionPagination>;
};

export enum PaginateTrackedSessionsResponseStatus {
  SomethingWentWrong = 'SOMETHING_WENT_WRONG',
  Success = 'SUCCESS',
  TrackerNotFound = 'TRACKER_NOT_FOUND'
}

export type PaginateTrackersInput = {
  filtering?: InputMaybe<TrackerFilterGroup>;
  pagination?: InputMaybe<PaginationInput>;
};

export type PaginateTrackersResponse = {
  __typename?: 'PaginateTrackersResponse';
  status: PaginateTrackersResponseStatus;
  trackers?: Maybe<TrackerPagination>;
};

export enum PaginateTrackersResponseStatus {
  SomethingWentWrong = 'SOMETHING_WENT_WRONG',
  Success = 'SUCCESS'
}

export type PaginationInput = {
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
};

export enum Permission {
  Captcha = 'CAPTCHA',
  CompanyCreate = 'COMPANY_CREATE',
  CompanyIndex = 'COMPANY_INDEX',
  CompanyPrioritizeEnrichers = 'COMPANY_PRIORITIZE_ENRICHERS',
  CompanyResolve = 'COMPANY_RESOLVE',
  Dashboard = 'DASHBOARD',
  EnricherDetails = 'ENRICHER_DETAILS',
  SystemStatus = 'SYSTEM_STATUS',
  TagIndex = 'TAG_INDEX',
  TagSave = 'TAG_SAVE',
  TrackedEventCreate = 'TRACKED_EVENT_CREATE',
  TrackedEventIndex = 'TRACKED_EVENT_INDEX',
  TrackedSessionIndex = 'TRACKED_SESSION_INDEX',
  TrackerDetails = 'TRACKER_DETAILS',
  TrackerIndex = 'TRACKER_INDEX',
  TrackerSave = 'TRACKER_SAVE',
  UserSave = 'USER_SAVE',
  UserSetup = 'USER_SETUP'
}

export type PrioritizeCompanyEnrichersResponse = {
  __typename?: 'PrioritizeCompanyEnrichersResponse';
  status: PrioritizeCompanyEnrichersResponseStatus;
};

export enum PrioritizeCompanyEnrichersResponseStatus {
  NotFound = 'NOT_FOUND',
  SomethingWentWrong = 'SOMETHING_WENT_WRONG',
  Success = 'SUCCESS'
}

export type Query = {
  __typename?: 'Query';
  allCaptchas: AllCaptchasResponse;
  companyById: CompanyByIdResponse;
  companyByIpAddress: CompanyByIpAddressResponse;
  me: MeResponse;
  paginateCompanies: PaginateCompaniesResponse;
  paginateTags: PaginateTagsResponse;
  paginateTrackedEvents: PaginateTrackedEventsResponse;
  paginateTrackedSessions: PaginateTrackedSessionsResponse;
  paginateTrackers: PaginateTrackersResponse;
  searchMissingCompanies: SearchMissingCompaniesResponse;
  tagById: TagByIdResponse;
  trackedEventById: TrackedEventByIdResponse;
  trackedSessionById: TrackedSessionByIdResponse;
  trackerById: TrackerByIdResponse;
};


export type QueryCompanyByIdArgs = {
  id: Scalars['ID'];
};


export type QueryCompanyByIpAddressArgs = {
  address: Scalars['String'];
};


export type QueryPaginateCompaniesArgs = {
  input?: InputMaybe<PaginateCompaniesInput>;
};


export type QueryPaginateTagsArgs = {
  input?: InputMaybe<PaginateTagsInput>;
};


export type QueryPaginateTrackedEventsArgs = {
  input?: InputMaybe<PaginateTrackedEventsInput>;
  trackedSessionId: Scalars['ID'];
};


export type QueryPaginateTrackedSessionsArgs = {
  input?: InputMaybe<PaginateTrackedSessionsInput>;
  trackerId: Scalars['ID'];
};


export type QueryPaginateTrackersArgs = {
  input?: InputMaybe<PaginateTrackersInput>;
};


export type QuerySearchMissingCompaniesArgs = {
  input: SearchMissingCompaniesInput;
};


export type QueryTagByIdArgs = {
  id: Scalars['ID'];
};


export type QueryTrackedEventByIdArgs = {
  id: Scalars['ID'];
};


export type QueryTrackedSessionByIdArgs = {
  id: Scalars['ID'];
};


export type QueryTrackerByIdArgs = {
  id: Scalars['ID'];
};

export type RelatedDomain = {
  __typename?: 'RelatedDomain';
  domain: Domain;
  reliability?: Maybe<Scalars['Int']>;
  score: Scalars['Int'];
  source: EnrichmentSource;
};

export type RelatedIpAddress = {
  __typename?: 'RelatedIpAddress';
  ipAddress: IpAddress;
  reliability?: Maybe<Scalars['Int']>;
  score: Scalars['Int'];
  source: EnrichmentSource;
};

export type RelatedTrackedSessionPagination = {
  __typename?: 'RelatedTrackedSessionPagination';
  currentPage: Scalars['Int'];
  data: Array<TrackedSession>;
  limit: Scalars['Int'];
  total: Scalars['Int'];
};

export type ResolvedCompany = {
  __typename?: 'ResolvedCompany';
  company: Company;
  relatedTrackedSessions: RelatedTrackedSessionPagination;
  resolvedAt: Scalars['DateTime'];
  score: Scalars['Int'];
};


export type ResolvedCompanyRelatedTrackedSessionsArgs = {
  input?: InputMaybe<PaginateRelatedTrackedSessionsInput>;
};

export type SaveAccessTokenInput = {
  name: Scalars['String'];
  permissionGroup?: InputMaybe<Scalars['String']>;
  permissions?: InputMaybe<Array<Permission>>;
  token: Scalars['String'];
};

export type SaveCaptchaResultResponse = {
  __typename?: 'SaveCaptchaResultResponse';
  captcha?: Maybe<Captcha>;
  status: SaveCaptchaResultResponseStatus;
};

export enum SaveCaptchaResultResponseStatus {
  NotFound = 'NOT_FOUND',
  SomethingWentWrong = 'SOMETHING_WENT_WRONG',
  Success = 'SUCCESS'
}

export type SaveTagInput = {
  name?: InputMaybe<Scalars['String']>;
  rules?: InputMaybe<TrackedSessionFilterGroup>;
};

export type SaveTagResponse = {
  __typename?: 'SaveTagResponse';
  status: SaveTagResponseStatus;
  tag?: Maybe<Tag>;
};

export enum SaveTagResponseStatus {
  MissingName = 'MISSING_NAME',
  SomethingWentWrong = 'SOMETHING_WENT_WRONG',
  Success = 'SUCCESS',
  TagNotFound = 'TAG_NOT_FOUND'
}

export type SaveTrackerInput = {
  domains?: InputMaybe<Array<Scalars['String']>>;
  name?: InputMaybe<Scalars['String']>;
};

export type SaveTrackerResponse = {
  __typename?: 'SaveTrackerResponse';
  status: SaveTrackerResponseStatus;
  tracker?: Maybe<Tracker>;
};

export enum SaveTrackerResponseStatus {
  MissingName = 'MISSING_NAME',
  SomethingWentWrong = 'SOMETHING_WENT_WRONG',
  Success = 'SUCCESS',
  TrackerNotFound = 'TRACKER_NOT_FOUND'
}

export type SaveUserInput = {
  email?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
  permissionGroup?: InputMaybe<Scalars['String']>;
  permissions?: InputMaybe<Array<Permission>>;
};

export type SaveUserResponse = {
  __typename?: 'SaveUserResponse';
  status: SaveUserResponseStatus;
  user?: Maybe<User>;
};

export enum SaveUserResponseStatus {
  EmailAlreadyTaken = 'EMAIL_ALREADY_TAKEN',
  MissingEmail = 'MISSING_EMAIL',
  MissingName = 'MISSING_NAME',
  MissingPassword = 'MISSING_PASSWORD',
  MissingPermissions = 'MISSING_PERMISSIONS',
  PermissionGroupNotFound = 'PERMISSION_GROUP_NOT_FOUND',
  SomethingWentWrong = 'SOMETHING_WENT_WRONG',
  Success = 'SUCCESS',
  UserNotFound = 'USER_NOT_FOUND'
}

export type SearchMissingCompaniesInput = {
  country: Scalars['String'];
  search: Scalars['String'];
};

export type SearchMissingCompaniesResponse = {
  __typename?: 'SearchMissingCompaniesResponse';
  companies?: Maybe<Array<MissingCompany>>;
  status: SearchMissingCompaniesResponseStatus;
};

export enum SearchMissingCompaniesResponseStatus {
  CountryNotFound = 'COUNTRY_NOT_FOUND',
  CountryNotImplemented = 'COUNTRY_NOT_IMPLEMENTED',
  SomethingWentWrong = 'SOMETHING_WENT_WRONG',
  Success = 'SUCCESS'
}

export type SetupUserInput = {
  accessTokens?: InputMaybe<Array<SaveAccessTokenInput>>;
  email: Scalars['String'];
  name: Scalars['String'];
  password: Scalars['String'];
  permissionGroup?: InputMaybe<Scalars['String']>;
  permissions?: InputMaybe<Array<Permission>>;
};

export type SetupUserResponse = {
  __typename?: 'SetupUserResponse';
  status: SetupUserResponseStatus;
  user?: Maybe<User>;
};

export enum SetupUserResponseStatus {
  EmailAlreadyTaken = 'EMAIL_ALREADY_TAKEN',
  MissingPermissions = 'MISSING_PERMISSIONS',
  PermissionGroupNotFound = 'PERMISSION_GROUP_NOT_FOUND',
  SomethingWentWrong = 'SOMETHING_WENT_WRONG',
  Success = 'SUCCESS'
}

export type Tag = {
  __typename?: 'Tag';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  reference?: Maybe<Scalars['String']>;
  reliability?: Maybe<Scalars['Int']>;
  rules?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type TagByIdResponse = {
  __typename?: 'TagByIdResponse';
  status: TagByIdResponseStatus;
  tag?: Maybe<Tag>;
};

export enum TagByIdResponseStatus {
  NotFound = 'NOT_FOUND',
  SomethingWentWrong = 'SOMETHING_WENT_WRONG',
  Success = 'SUCCESS'
}

export type TagFilter = {
  eq?: InputMaybe<Scalars['String']>;
  field: TagFilteringFields;
  gt?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  like?: InputMaybe<Scalars['String']>;
  lt?: InputMaybe<Scalars['String']>;
  ne?: InputMaybe<Scalars['String']>;
  ni?: InputMaybe<Array<Scalars['String']>>;
};

export type TagFilterGroup = {
  filters?: InputMaybe<Array<TagFilter>>;
  groups?: InputMaybe<Array<TagFilterGroup>>;
  operator: FilteringGroupOperator;
};

export enum TagFilteringFields {
  Id = 'ID',
  Name = 'NAME'
}

export type TagPagination = {
  __typename?: 'TagPagination';
  currentPage: Scalars['Int'];
  data: Array<Tag>;
  limit: Scalars['Int'];
  total: Scalars['Int'];
};

export type TrackEventInput = {
  emailAddress?: InputMaybe<Scalars['String']>;
  ipAddress?: InputMaybe<Scalars['String']>;
  type: TrackEventType;
};

export type TrackEventResponse = {
  __typename?: 'TrackEventResponse';
  session?: Maybe<Scalars['String']>;
  status: TrackEventResponseStatus;
};

export enum TrackEventResponseStatus {
  InvalidEmailAddress = 'INVALID_EMAIL_ADDRESS',
  SomethingWentWrong = 'SOMETHING_WENT_WRONG',
  Success = 'SUCCESS',
  TrackerNotFound = 'TRACKER_NOT_FOUND'
}

export enum TrackEventType {
  Email = 'EMAIL'
}

export type TrackedEvent = {
  __typename?: 'TrackedEvent';
  createdAt: Scalars['DateTime'];
  emailAddress?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  ipAddress?: Maybe<IpAddress>;
  referrer?: Maybe<Scalars['String']>;
  type: TrackedEventType;
  url?: Maybe<Scalars['String']>;
  userAgent?: Maybe<Scalars['String']>;
};

export type TrackedEventByIdResponse = {
  __typename?: 'TrackedEventByIdResponse';
  status: TrackedEventByIdResponseStatus;
  trackedEvent?: Maybe<TrackedEvent>;
};

export enum TrackedEventByIdResponseStatus {
  NotFound = 'NOT_FOUND',
  SomethingWentWrong = 'SOMETHING_WENT_WRONG',
  Success = 'SUCCESS'
}

export type TrackedEventFilter = {
  eq?: InputMaybe<Scalars['String']>;
  field: TrackedEventFilteringFields;
  gt?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  like?: InputMaybe<Scalars['String']>;
  lt?: InputMaybe<Scalars['String']>;
  ne?: InputMaybe<Scalars['String']>;
  ni?: InputMaybe<Array<Scalars['String']>>;
};

export type TrackedEventFilterGroup = {
  filters?: InputMaybe<Array<TrackedEventFilter>>;
  groups?: InputMaybe<Array<TrackedEventFilterGroup>>;
  operator: FilteringGroupOperator;
};

export enum TrackedEventFilteringFields {
  Date = 'DATE',
  Domain = 'DOMAIN',
  Email = 'EMAIL',
  IpAddress = 'IP_ADDRESS',
  IpAddressCountry = 'IP_ADDRESS_COUNTRY',
  Referrer = 'REFERRER',
  Type = 'TYPE'
}

export type TrackedEventPagination = {
  __typename?: 'TrackedEventPagination';
  currentPage: Scalars['Int'];
  data: Array<TrackedEvent>;
  limit: Scalars['Int'];
  total: Scalars['Int'];
};

export enum TrackedEventType {
  Email = 'EMAIL',
  Request = 'REQUEST'
}

export type TrackedSession = {
  __typename?: 'TrackedSession';
  createdAt: Scalars['DateTime'];
  domains: Array<Domain>;
  emails: Array<Scalars['String']>;
  hash: Scalars['String'];
  id: Scalars['ID'];
  ipAddresses: Array<IpAddress>;
  latestEventAt?: Maybe<Scalars['DateTime']>;
  resolvedCompanies?: Maybe<Array<ResolvedCompany>>;
  start: Scalars['DateTime'];
  stats: TrackedSessionStats;
  tags?: Maybe<Array<Tag>>;
};

export type TrackedSessionByIdResponse = {
  __typename?: 'TrackedSessionByIdResponse';
  status: TrackedSessionByIdResponseStatus;
  trackedSession?: Maybe<TrackedSession>;
};

export enum TrackedSessionByIdResponseStatus {
  NotFound = 'NOT_FOUND',
  SomethingWentWrong = 'SOMETHING_WENT_WRONG',
  Success = 'SUCCESS'
}

export type TrackedSessionFilter = {
  eq?: InputMaybe<Scalars['String']>;
  field: TrackedSessionFilteringFields;
  gt?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  like?: InputMaybe<Scalars['String']>;
  lt?: InputMaybe<Scalars['String']>;
  ne?: InputMaybe<Scalars['String']>;
  ni?: InputMaybe<Array<Scalars['String']>>;
};

export type TrackedSessionFilterGroup = {
  filters?: InputMaybe<Array<TrackedSessionFilter>>;
  groups?: InputMaybe<Array<TrackedSessionFilterGroup>>;
  operator: FilteringGroupOperator;
};

export enum TrackedSessionFilteringFields {
  CompanyId = 'COMPANY_ID',
  CompanyName = 'COMPANY_NAME',
  CompanyReference = 'COMPANY_REFERENCE',
  Domains = 'DOMAINS',
  Id = 'ID',
  IpAddress = 'IP_ADDRESS',
  IpAddressCountry = 'IP_ADDRESS_COUNTRY',
  Tags = 'TAGS',
  TrackedEventDate = 'TRACKED_EVENT_DATE',
  TrackedEventEmail = 'TRACKED_EVENT_EMAIL',
  TrackedEventEmailCount = 'TRACKED_EVENT_EMAIL_COUNT',
  TrackedEventReferrer = 'TRACKED_EVENT_REFERRER',
  TrackedEventRequestCount = 'TRACKED_EVENT_REQUEST_COUNT',
  TrackedEventType = 'TRACKED_EVENT_TYPE'
}

export type TrackedSessionPagination = {
  __typename?: 'TrackedSessionPagination';
  currentPage: Scalars['Int'];
  data: Array<TrackedSession>;
  limit: Scalars['Int'];
  total: Scalars['Int'];
};

export type TrackedSessionStats = {
  __typename?: 'TrackedSessionStats';
  totalEmailTrackedEvents: Scalars['Int'];
  totalRequestTrackedEvents: Scalars['Int'];
};

export type Tracker = {
  __typename?: 'Tracker';
  domains?: Maybe<Array<Scalars['String']>>;
  hash: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type TrackerByIdResponse = {
  __typename?: 'TrackerByIdResponse';
  status: TrackerByIdResponseStatus;
  tracker?: Maybe<Tracker>;
};

export enum TrackerByIdResponseStatus {
  NotFound = 'NOT_FOUND',
  SomethingWentWrong = 'SOMETHING_WENT_WRONG',
  Success = 'SUCCESS'
}

export type TrackerFilter = {
  eq?: InputMaybe<Scalars['String']>;
  field: TrackerFilteringFields;
  gt?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  like?: InputMaybe<Scalars['String']>;
  lt?: InputMaybe<Scalars['String']>;
  ne?: InputMaybe<Scalars['String']>;
  ni?: InputMaybe<Array<Scalars['String']>>;
};

export type TrackerFilterGroup = {
  filters?: InputMaybe<Array<TrackerFilter>>;
  groups?: InputMaybe<Array<TrackerFilterGroup>>;
  operator: FilteringGroupOperator;
};

export enum TrackerFilteringFields {
  Domains = 'DOMAINS',
  Id = 'ID',
  Name = 'NAME'
}

export type TrackerPagination = {
  __typename?: 'TrackerPagination';
  currentPage: Scalars['Int'];
  data: Array<Tracker>;
  limit: Scalars['Int'];
  total: Scalars['Int'];
};

export type User = {
  __typename?: 'User';
  email: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  permissions: Array<Permission>;
};

export type LocalAddressFragment = { __typename?: 'Address', id: string, source?: string | null, type?: AddressType | null, street?: string | null, houseNumber?: string | null, houseNumberAddition?: string | null, place?: string | null, zipcode?: string | null, latitude?: number | null, longitude?: number | null, country?: { __typename?: 'Country', id: string, name: string, iso: string } | null };

export type LocalCompanyFragment = { __typename?: 'Company', id: string, names: Array<string>, reference: string, logo?: string | null };

export type LocalTrackedEventFragment = { __typename?: 'TrackedEvent', id: string, userAgent?: string | null, referrer?: string | null, type: TrackedEventType, url?: string | null, emailAddress?: string | null, createdAt: any, ipAddress?: { __typename?: 'IpAddress', id: string, address: string } | null };

export type LocalTrackedSessionFragment = { __typename?: 'TrackedSession', id: string, start: any, latestEventAt?: any | null };

export type LocalTrackerFragment = { __typename?: 'Tracker', id: string, name: string, domains?: Array<string> | null };

export type LocalUserFragment = { __typename?: 'User', id: string, name: string, email: string, permissions: Array<Permission> };

export type CreateCompanyMutationVariables = Exact<{
  input: CreateCompanyInput;
}>;


export type CreateCompanyMutation = { __typename?: 'Mutation', createCompany: { __typename?: 'CreateCompanyResponse', status: CreateCompanyResponseStatus, company?: { __typename?: 'Company', id: string, names: Array<string>, reference: string, logo?: string | null } | null } };

export type LoginMutationVariables = Exact<{
  input: LoginInput;
}>;


export type LoginMutation = { __typename?: 'Mutation', login: { __typename?: 'LoginResponse', status: LoginResponseStatus, token?: string | null, user?: { __typename?: 'User', id: string, name: string, email: string, permissions: Array<Permission> } | null } };

export type PrioritizeCompanyEnrichersMutationVariables = Exact<{
  companyId: Scalars['ID'];
}>;


export type PrioritizeCompanyEnrichersMutation = { __typename?: 'Mutation', prioritizeCompanyEnrichers: { __typename?: 'PrioritizeCompanyEnrichersResponse', status: PrioritizeCompanyEnrichersResponseStatus } };

export type SaveCaptchaResultMutationVariables = Exact<{
  captchaId: Scalars['ID'];
  result: Scalars['String'];
}>;


export type SaveCaptchaResultMutation = { __typename?: 'Mutation', saveCaptchaResult: { __typename?: 'SaveCaptchaResultResponse', status: SaveCaptchaResultResponseStatus, captcha?: { __typename?: 'Captcha', id: string, result?: string | null } | null } };

export type AllCaptchasQueryVariables = Exact<{ [key: string]: never; }>;


export type AllCaptchasQuery = { __typename?: 'Query', allCaptchas: { __typename?: 'AllCaptchasResponse', status: AllCaptchasResponseStatus, captchas: Array<{ __typename?: 'Captcha', id: string, hostname: string, type: string, result?: string | null, image: string }> } };

export type CompanyByIdQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type CompanyByIdQuery = { __typename?: 'Query', companyById: { __typename?: 'CompanyByIdResponse', status: CompanyByIdResponseStatus, company?: { __typename?: 'Company', id: string, names: Array<string>, reference: string, logo?: string | null, addresses?: Array<{ __typename?: 'Address', id: string, source?: string | null, type?: AddressType | null, street?: string | null, houseNumber?: string | null, houseNumberAddition?: string | null, place?: string | null, zipcode?: string | null, latitude?: number | null, longitude?: number | null, country?: { __typename?: 'Country', id: string, name: string, iso: string } | null }> | null, communications?: Array<{ __typename?: 'Communication', id: string, source?: string | null, type?: CommunicationType | null, value?: string | null }> | null, tags?: Array<{ __typename?: 'Tag', id: string, source?: string | null, type?: string | null, reference?: string | null, name?: string | null }> | null, relatedDomains?: Array<{ __typename?: 'RelatedDomain', source: EnrichmentSource, score: number, domain: { __typename?: 'Domain', id: string, fqdn: string, relatedIpAddresses?: Array<{ __typename?: 'RelatedIpAddress', source: EnrichmentSource, score: number, ipAddress: { __typename?: 'IpAddress', id: string, address: string } }> | null } }> | null, relatedIpAddresses?: Array<{ __typename?: 'RelatedIpAddress', source: EnrichmentSource, score: number, ipAddress: { __typename?: 'IpAddress', id: string, address: string } }> | null } | null } };

export type CompanyByIpAddressQueryVariables = Exact<{
  address: Scalars['String'];
}>;


export type CompanyByIpAddressQuery = { __typename?: 'Query', companyByIpAddress: { __typename?: 'CompanyByIpAddressResponse', score?: number | null, status: CompanyByIpAddressResponseStatus, company?: { __typename?: 'Company', id: string, names: Array<string>, reference: string, logo?: string | null } | null } };

export type CurrentUserQueryVariables = Exact<{ [key: string]: never; }>;


export type CurrentUserQuery = { __typename?: 'Query', me: { __typename?: 'MeResponse', status: MeResponseStatus, user?: { __typename?: 'User', id: string, name: string, email: string, permissions: Array<Permission> } | null } };

export type PaginateCompaniesQueryVariables = Exact<{
  search?: InputMaybe<Scalars['String']>;
  pagination?: InputMaybe<PaginationInput>;
}>;


export type PaginateCompaniesQuery = { __typename?: 'Query', paginateCompanies: { __typename?: 'PaginateCompaniesResponse', status: PaginateCompaniesResponseStatus, companies?: { __typename?: 'CompanyPagination', total: number, currentPage: number, limit: number, data: Array<{ __typename?: 'Company', id: string, names: Array<string>, reference: string, logo?: string | null }> } | null } };

export type PaginateTrackedEventsQueryVariables = Exact<{
  trackedSessionId: Scalars['ID'];
  pagination?: InputMaybe<PaginationInput>;
}>;


export type PaginateTrackedEventsQuery = { __typename?: 'Query', paginateTrackedEvents: { __typename?: 'PaginateTrackedEventsResponse', status: PaginateTrackedEventsResponseStatus, trackedEvents?: { __typename?: 'TrackedEventPagination', total: number, currentPage: number, limit: number, data: Array<{ __typename?: 'TrackedEvent', id: string, userAgent?: string | null, referrer?: string | null, type: TrackedEventType, url?: string | null, emailAddress?: string | null, createdAt: any, ipAddress?: { __typename?: 'IpAddress', id: string, address: string } | null }> } | null } };

export type PaginateTrackedSessionsQueryVariables = Exact<{
  trackerId: Scalars['ID'];
  pagination?: InputMaybe<PaginationInput>;
}>;


export type PaginateTrackedSessionsQuery = { __typename?: 'Query', paginateTrackedSessions: { __typename?: 'PaginateTrackedSessionsResponse', status: PaginateTrackedSessionsResponseStatus, trackedSessions?: { __typename?: 'TrackedSessionPagination', total: number, currentPage: number, limit: number, data: Array<{ __typename?: 'TrackedSession', emails: Array<string>, id: string, start: any, latestEventAt?: any | null, domains: Array<{ __typename?: 'Domain', id: string, fqdn: string }>, resolvedCompanies?: Array<{ __typename?: 'ResolvedCompany', company: { __typename?: 'Company', id: string, names: Array<string> } }> | null }> } | null } };

export type PaginateTrackersQueryVariables = Exact<{
  pagination?: InputMaybe<PaginationInput>;
}>;


export type PaginateTrackersQuery = { __typename?: 'Query', paginateTrackers: { __typename?: 'PaginateTrackersResponse', status: PaginateTrackersResponseStatus, trackers?: { __typename?: 'TrackerPagination', total: number, currentPage: number, limit: number, data: Array<{ __typename?: 'Tracker', id: string, name: string, domains?: Array<string> | null }> } | null } };

export type SearchMissingCompaniesQueryVariables = Exact<{
  search: Scalars['String'];
  country: Scalars['String'];
}>;


export type SearchMissingCompaniesQuery = { __typename?: 'Query', searchMissingCompanies: { __typename?: 'SearchMissingCompaniesResponse', status: SearchMissingCompaniesResponseStatus, companies?: Array<{ __typename?: 'MissingCompany', name?: string | null, reference?: string | null }> | null } };

export type TrackedSessionByIdQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type TrackedSessionByIdQuery = { __typename?: 'Query', trackedSessionById: { __typename?: 'TrackedSessionByIdResponse', status: TrackedSessionByIdResponseStatus, trackedSession?: { __typename?: 'TrackedSession', id: string, start: any, latestEventAt?: any | null, resolvedCompanies?: Array<{ __typename?: 'ResolvedCompany', score: number, resolvedAt: any, company: { __typename?: 'Company', id: string, names: Array<string>, reference: string, logo?: string | null } }> | null } | null } };

export const LocalAddressFragmentDoc = gql`
    fragment LocalAddress on Address {
  id
  source
  type
  street
  houseNumber
  houseNumberAddition
  place
  zipcode
  country {
    id
    name
    iso
  }
  latitude
  longitude
}
    `;
export const LocalCompanyFragmentDoc = gql`
    fragment LocalCompany on Company {
  id
  names
  reference
  logo
}
    `;
export const LocalTrackedEventFragmentDoc = gql`
    fragment LocalTrackedEvent on TrackedEvent {
  id
  userAgent
  referrer
  type
  url
  emailAddress
  ipAddress {
    id
    address
  }
  createdAt
}
    `;
export const LocalTrackedSessionFragmentDoc = gql`
    fragment LocalTrackedSession on TrackedSession {
  id
  start
  latestEventAt
}
    `;
export const LocalTrackerFragmentDoc = gql`
    fragment LocalTracker on Tracker {
  id
  name
  domains
}
    `;
export const LocalUserFragmentDoc = gql`
    fragment LocalUser on User {
  id
  name
  email
  permissions
}
    `;
export const CreateCompanyDocument = gql`
    mutation createCompany($input: CreateCompanyInput!) {
  createCompany(input: $input) {
    status
    company {
      ...LocalCompany
    }
  }
}
    ${LocalCompanyFragmentDoc}`;
export type CreateCompanyMutationFn = Apollo.MutationFunction<CreateCompanyMutation, CreateCompanyMutationVariables>;

/**
 * __useCreateCompanyMutation__
 *
 * To run a mutation, you first call `useCreateCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCompanyMutation, { data, loading, error }] = useCreateCompanyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCompanyMutation(baseOptions?: Apollo.MutationHookOptions<CreateCompanyMutation, CreateCompanyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCompanyMutation, CreateCompanyMutationVariables>(CreateCompanyDocument, options);
      }
export type CreateCompanyMutationHookResult = ReturnType<typeof useCreateCompanyMutation>;
export type CreateCompanyMutationResult = Apollo.MutationResult<CreateCompanyMutation>;
export type CreateCompanyMutationOptions = Apollo.BaseMutationOptions<CreateCompanyMutation, CreateCompanyMutationVariables>;
export const LoginDocument = gql`
    mutation login($input: LoginInput!) {
  login(input: $input) {
    status
    token
    user {
      ...LocalUser
    }
  }
}
    ${LocalUserFragmentDoc}`;
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, options);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const PrioritizeCompanyEnrichersDocument = gql`
    mutation prioritizeCompanyEnrichers($companyId: ID!) {
  prioritizeCompanyEnrichers(companyId: $companyId) {
    status
  }
}
    `;
export type PrioritizeCompanyEnrichersMutationFn = Apollo.MutationFunction<PrioritizeCompanyEnrichersMutation, PrioritizeCompanyEnrichersMutationVariables>;

/**
 * __usePrioritizeCompanyEnrichersMutation__
 *
 * To run a mutation, you first call `usePrioritizeCompanyEnrichersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePrioritizeCompanyEnrichersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [prioritizeCompanyEnrichersMutation, { data, loading, error }] = usePrioritizeCompanyEnrichersMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function usePrioritizeCompanyEnrichersMutation(baseOptions?: Apollo.MutationHookOptions<PrioritizeCompanyEnrichersMutation, PrioritizeCompanyEnrichersMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PrioritizeCompanyEnrichersMutation, PrioritizeCompanyEnrichersMutationVariables>(PrioritizeCompanyEnrichersDocument, options);
      }
export type PrioritizeCompanyEnrichersMutationHookResult = ReturnType<typeof usePrioritizeCompanyEnrichersMutation>;
export type PrioritizeCompanyEnrichersMutationResult = Apollo.MutationResult<PrioritizeCompanyEnrichersMutation>;
export type PrioritizeCompanyEnrichersMutationOptions = Apollo.BaseMutationOptions<PrioritizeCompanyEnrichersMutation, PrioritizeCompanyEnrichersMutationVariables>;
export const SaveCaptchaResultDocument = gql`
    mutation saveCaptchaResult($captchaId: ID!, $result: String!) {
  saveCaptchaResult(captchaId: $captchaId, result: $result) {
    status
    captcha {
      id
      result
    }
  }
}
    `;
export type SaveCaptchaResultMutationFn = Apollo.MutationFunction<SaveCaptchaResultMutation, SaveCaptchaResultMutationVariables>;

/**
 * __useSaveCaptchaResultMutation__
 *
 * To run a mutation, you first call `useSaveCaptchaResultMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveCaptchaResultMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveCaptchaResultMutation, { data, loading, error }] = useSaveCaptchaResultMutation({
 *   variables: {
 *      captchaId: // value for 'captchaId'
 *      result: // value for 'result'
 *   },
 * });
 */
export function useSaveCaptchaResultMutation(baseOptions?: Apollo.MutationHookOptions<SaveCaptchaResultMutation, SaveCaptchaResultMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveCaptchaResultMutation, SaveCaptchaResultMutationVariables>(SaveCaptchaResultDocument, options);
      }
export type SaveCaptchaResultMutationHookResult = ReturnType<typeof useSaveCaptchaResultMutation>;
export type SaveCaptchaResultMutationResult = Apollo.MutationResult<SaveCaptchaResultMutation>;
export type SaveCaptchaResultMutationOptions = Apollo.BaseMutationOptions<SaveCaptchaResultMutation, SaveCaptchaResultMutationVariables>;
export const AllCaptchasDocument = gql`
    query allCaptchas {
  allCaptchas {
    captchas {
      id
      hostname
      type
      result
      image
    }
    status
  }
}
    `;

/**
 * __useAllCaptchasQuery__
 *
 * To run a query within a React component, call `useAllCaptchasQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllCaptchasQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllCaptchasQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllCaptchasQuery(baseOptions?: Apollo.QueryHookOptions<AllCaptchasQuery, AllCaptchasQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllCaptchasQuery, AllCaptchasQueryVariables>(AllCaptchasDocument, options);
      }
export function useAllCaptchasLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllCaptchasQuery, AllCaptchasQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllCaptchasQuery, AllCaptchasQueryVariables>(AllCaptchasDocument, options);
        }
export type AllCaptchasQueryHookResult = ReturnType<typeof useAllCaptchasQuery>;
export type AllCaptchasLazyQueryHookResult = ReturnType<typeof useAllCaptchasLazyQuery>;
export type AllCaptchasQueryResult = Apollo.QueryResult<AllCaptchasQuery, AllCaptchasQueryVariables>;
export const CompanyByIdDocument = gql`
    query companyById($id: ID!) {
  companyById(id: $id) {
    company {
      ...LocalCompany
      addresses {
        ...LocalAddress
      }
      communications {
        id
        source
        type
        value
      }
      tags {
        id
        source
        type
        reference
        name
      }
      relatedDomains {
        source
        score
        domain {
          id
          fqdn
          relatedIpAddresses {
            source
            score
            ipAddress {
              id
              address
            }
          }
        }
      }
      relatedIpAddresses {
        source
        score
        ipAddress {
          id
          address
        }
      }
    }
    status
  }
}
    ${LocalCompanyFragmentDoc}
${LocalAddressFragmentDoc}`;

/**
 * __useCompanyByIdQuery__
 *
 * To run a query within a React component, call `useCompanyByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCompanyByIdQuery(baseOptions: Apollo.QueryHookOptions<CompanyByIdQuery, CompanyByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CompanyByIdQuery, CompanyByIdQueryVariables>(CompanyByIdDocument, options);
      }
export function useCompanyByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CompanyByIdQuery, CompanyByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CompanyByIdQuery, CompanyByIdQueryVariables>(CompanyByIdDocument, options);
        }
export type CompanyByIdQueryHookResult = ReturnType<typeof useCompanyByIdQuery>;
export type CompanyByIdLazyQueryHookResult = ReturnType<typeof useCompanyByIdLazyQuery>;
export type CompanyByIdQueryResult = Apollo.QueryResult<CompanyByIdQuery, CompanyByIdQueryVariables>;
export const CompanyByIpAddressDocument = gql`
    query companyByIpAddress($address: String!) {
  companyByIpAddress(address: $address) {
    company {
      ...LocalCompany
    }
    score
    status
  }
}
    ${LocalCompanyFragmentDoc}`;

/**
 * __useCompanyByIpAddressQuery__
 *
 * To run a query within a React component, call `useCompanyByIpAddressQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyByIpAddressQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyByIpAddressQuery({
 *   variables: {
 *      address: // value for 'address'
 *   },
 * });
 */
export function useCompanyByIpAddressQuery(baseOptions: Apollo.QueryHookOptions<CompanyByIpAddressQuery, CompanyByIpAddressQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CompanyByIpAddressQuery, CompanyByIpAddressQueryVariables>(CompanyByIpAddressDocument, options);
      }
export function useCompanyByIpAddressLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CompanyByIpAddressQuery, CompanyByIpAddressQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CompanyByIpAddressQuery, CompanyByIpAddressQueryVariables>(CompanyByIpAddressDocument, options);
        }
export type CompanyByIpAddressQueryHookResult = ReturnType<typeof useCompanyByIpAddressQuery>;
export type CompanyByIpAddressLazyQueryHookResult = ReturnType<typeof useCompanyByIpAddressLazyQuery>;
export type CompanyByIpAddressQueryResult = Apollo.QueryResult<CompanyByIpAddressQuery, CompanyByIpAddressQueryVariables>;
export const CurrentUserDocument = gql`
    query currentUser {
  me {
    status
    user {
      ...LocalUser
    }
  }
}
    ${LocalUserFragmentDoc}`;

/**
 * __useCurrentUserQuery__
 *
 * To run a query within a React component, call `useCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentUserQuery(baseOptions?: Apollo.QueryHookOptions<CurrentUserQuery, CurrentUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CurrentUserQuery, CurrentUserQueryVariables>(CurrentUserDocument, options);
      }
export function useCurrentUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CurrentUserQuery, CurrentUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CurrentUserQuery, CurrentUserQueryVariables>(CurrentUserDocument, options);
        }
export type CurrentUserQueryHookResult = ReturnType<typeof useCurrentUserQuery>;
export type CurrentUserLazyQueryHookResult = ReturnType<typeof useCurrentUserLazyQuery>;
export type CurrentUserQueryResult = Apollo.QueryResult<CurrentUserQuery, CurrentUserQueryVariables>;
export const PaginateCompaniesDocument = gql`
    query paginateCompanies($search: String, $pagination: PaginationInput) {
  paginateCompanies(input: {search: $search, pagination: $pagination}) {
    companies {
      total
      currentPage
      limit
      data {
        ...LocalCompany
      }
    }
    status
  }
}
    ${LocalCompanyFragmentDoc}`;

/**
 * __usePaginateCompaniesQuery__
 *
 * To run a query within a React component, call `usePaginateCompaniesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaginateCompaniesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaginateCompaniesQuery({
 *   variables: {
 *      search: // value for 'search'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function usePaginateCompaniesQuery(baseOptions?: Apollo.QueryHookOptions<PaginateCompaniesQuery, PaginateCompaniesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PaginateCompaniesQuery, PaginateCompaniesQueryVariables>(PaginateCompaniesDocument, options);
      }
export function usePaginateCompaniesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PaginateCompaniesQuery, PaginateCompaniesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PaginateCompaniesQuery, PaginateCompaniesQueryVariables>(PaginateCompaniesDocument, options);
        }
export type PaginateCompaniesQueryHookResult = ReturnType<typeof usePaginateCompaniesQuery>;
export type PaginateCompaniesLazyQueryHookResult = ReturnType<typeof usePaginateCompaniesLazyQuery>;
export type PaginateCompaniesQueryResult = Apollo.QueryResult<PaginateCompaniesQuery, PaginateCompaniesQueryVariables>;
export const PaginateTrackedEventsDocument = gql`
    query paginateTrackedEvents($trackedSessionId: ID!, $pagination: PaginationInput) {
  paginateTrackedEvents(
    trackedSessionId: $trackedSessionId
    input: {pagination: $pagination}
  ) {
    trackedEvents {
      total
      currentPage
      limit
      data {
        ...LocalTrackedEvent
      }
    }
    status
  }
}
    ${LocalTrackedEventFragmentDoc}`;

/**
 * __usePaginateTrackedEventsQuery__
 *
 * To run a query within a React component, call `usePaginateTrackedEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaginateTrackedEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaginateTrackedEventsQuery({
 *   variables: {
 *      trackedSessionId: // value for 'trackedSessionId'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function usePaginateTrackedEventsQuery(baseOptions: Apollo.QueryHookOptions<PaginateTrackedEventsQuery, PaginateTrackedEventsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PaginateTrackedEventsQuery, PaginateTrackedEventsQueryVariables>(PaginateTrackedEventsDocument, options);
      }
export function usePaginateTrackedEventsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PaginateTrackedEventsQuery, PaginateTrackedEventsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PaginateTrackedEventsQuery, PaginateTrackedEventsQueryVariables>(PaginateTrackedEventsDocument, options);
        }
export type PaginateTrackedEventsQueryHookResult = ReturnType<typeof usePaginateTrackedEventsQuery>;
export type PaginateTrackedEventsLazyQueryHookResult = ReturnType<typeof usePaginateTrackedEventsLazyQuery>;
export type PaginateTrackedEventsQueryResult = Apollo.QueryResult<PaginateTrackedEventsQuery, PaginateTrackedEventsQueryVariables>;
export const PaginateTrackedSessionsDocument = gql`
    query paginateTrackedSessions($trackerId: ID!, $pagination: PaginationInput) {
  paginateTrackedSessions(trackerId: $trackerId, input: {pagination: $pagination}) {
    trackedSessions {
      total
      currentPage
      limit
      data {
        ...LocalTrackedSession
        emails
        domains {
          id
          fqdn
        }
        resolvedCompanies {
          company {
            id
            names
          }
        }
      }
    }
    status
  }
}
    ${LocalTrackedSessionFragmentDoc}`;

/**
 * __usePaginateTrackedSessionsQuery__
 *
 * To run a query within a React component, call `usePaginateTrackedSessionsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaginateTrackedSessionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaginateTrackedSessionsQuery({
 *   variables: {
 *      trackerId: // value for 'trackerId'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function usePaginateTrackedSessionsQuery(baseOptions: Apollo.QueryHookOptions<PaginateTrackedSessionsQuery, PaginateTrackedSessionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PaginateTrackedSessionsQuery, PaginateTrackedSessionsQueryVariables>(PaginateTrackedSessionsDocument, options);
      }
export function usePaginateTrackedSessionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PaginateTrackedSessionsQuery, PaginateTrackedSessionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PaginateTrackedSessionsQuery, PaginateTrackedSessionsQueryVariables>(PaginateTrackedSessionsDocument, options);
        }
export type PaginateTrackedSessionsQueryHookResult = ReturnType<typeof usePaginateTrackedSessionsQuery>;
export type PaginateTrackedSessionsLazyQueryHookResult = ReturnType<typeof usePaginateTrackedSessionsLazyQuery>;
export type PaginateTrackedSessionsQueryResult = Apollo.QueryResult<PaginateTrackedSessionsQuery, PaginateTrackedSessionsQueryVariables>;
export const PaginateTrackersDocument = gql`
    query paginateTrackers($pagination: PaginationInput) {
  paginateTrackers(input: {pagination: $pagination}) {
    trackers {
      total
      currentPage
      limit
      data {
        ...LocalTracker
      }
    }
    status
  }
}
    ${LocalTrackerFragmentDoc}`;

/**
 * __usePaginateTrackersQuery__
 *
 * To run a query within a React component, call `usePaginateTrackersQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaginateTrackersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaginateTrackersQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function usePaginateTrackersQuery(baseOptions?: Apollo.QueryHookOptions<PaginateTrackersQuery, PaginateTrackersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PaginateTrackersQuery, PaginateTrackersQueryVariables>(PaginateTrackersDocument, options);
      }
export function usePaginateTrackersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PaginateTrackersQuery, PaginateTrackersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PaginateTrackersQuery, PaginateTrackersQueryVariables>(PaginateTrackersDocument, options);
        }
export type PaginateTrackersQueryHookResult = ReturnType<typeof usePaginateTrackersQuery>;
export type PaginateTrackersLazyQueryHookResult = ReturnType<typeof usePaginateTrackersLazyQuery>;
export type PaginateTrackersQueryResult = Apollo.QueryResult<PaginateTrackersQuery, PaginateTrackersQueryVariables>;
export const SearchMissingCompaniesDocument = gql`
    query searchMissingCompanies($search: String!, $country: String!) {
  searchMissingCompanies(input: {search: $search, country: $country}) {
    companies {
      name
      reference
    }
    status
  }
}
    `;

/**
 * __useSearchMissingCompaniesQuery__
 *
 * To run a query within a React component, call `useSearchMissingCompaniesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchMissingCompaniesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchMissingCompaniesQuery({
 *   variables: {
 *      search: // value for 'search'
 *      country: // value for 'country'
 *   },
 * });
 */
export function useSearchMissingCompaniesQuery(baseOptions: Apollo.QueryHookOptions<SearchMissingCompaniesQuery, SearchMissingCompaniesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchMissingCompaniesQuery, SearchMissingCompaniesQueryVariables>(SearchMissingCompaniesDocument, options);
      }
export function useSearchMissingCompaniesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchMissingCompaniesQuery, SearchMissingCompaniesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchMissingCompaniesQuery, SearchMissingCompaniesQueryVariables>(SearchMissingCompaniesDocument, options);
        }
export type SearchMissingCompaniesQueryHookResult = ReturnType<typeof useSearchMissingCompaniesQuery>;
export type SearchMissingCompaniesLazyQueryHookResult = ReturnType<typeof useSearchMissingCompaniesLazyQuery>;
export type SearchMissingCompaniesQueryResult = Apollo.QueryResult<SearchMissingCompaniesQuery, SearchMissingCompaniesQueryVariables>;
export const TrackedSessionByIdDocument = gql`
    query trackedSessionById($id: ID!) {
  trackedSessionById(id: $id) {
    trackedSession {
      ...LocalTrackedSession
      resolvedCompanies {
        score
        resolvedAt
        company {
          ...LocalCompany
        }
      }
    }
    status
  }
}
    ${LocalTrackedSessionFragmentDoc}
${LocalCompanyFragmentDoc}`;

/**
 * __useTrackedSessionByIdQuery__
 *
 * To run a query within a React component, call `useTrackedSessionByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useTrackedSessionByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTrackedSessionByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTrackedSessionByIdQuery(baseOptions: Apollo.QueryHookOptions<TrackedSessionByIdQuery, TrackedSessionByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TrackedSessionByIdQuery, TrackedSessionByIdQueryVariables>(TrackedSessionByIdDocument, options);
      }
export function useTrackedSessionByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TrackedSessionByIdQuery, TrackedSessionByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TrackedSessionByIdQuery, TrackedSessionByIdQueryVariables>(TrackedSessionByIdDocument, options);
        }
export type TrackedSessionByIdQueryHookResult = ReturnType<typeof useTrackedSessionByIdQuery>;
export type TrackedSessionByIdLazyQueryHookResult = ReturnType<typeof useTrackedSessionByIdLazyQuery>;
export type TrackedSessionByIdQueryResult = Apollo.QueryResult<TrackedSessionByIdQuery, TrackedSessionByIdQueryVariables>;