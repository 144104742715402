import { TextField, TextFieldProps } from "@mui/material";
import React from "react";
import { Control, Controller, FieldValues } from "react-hook-form";
import { IMaskInput } from "react-imask";

type IpAddressFieldProps<T extends FieldValues = any> = {
  name: string;
  control: Control<T>;
} & TextFieldProps;

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

const TextMaskCustom = React.forwardRef<HTMLInputElement, CustomProps>(
  function TextMaskCustom({ onChange, ...props }, ref) {
    return (
      <IMaskInput
        {...props}
        mask="0[00].0[00].0[00].0[00]"
        inputRef={ref}
        onAccept={(value: any) =>
          onChange({ target: { name: props.name, value } })
        }
        overwrite
      />
    );
  }
);

const IpAddressField = ({ control, ...props }: IpAddressFieldProps) => {
  return (
    <Controller
      control={control}
      name={props.name}
      render={({ field: { onChange, ...controllerProps } }) => (
        <TextField
          {...props}
          {...controllerProps}
          onChange={onChange}
          InputProps={{
            inputComponent: TextMaskCustom as any,
            inputProps: {
              component: IMaskInput,
            },
          }}
        />
      )}
    />
  );
};

export default IpAddressField;
