import { useApolloClient } from "@apollo/client";
import React from "react";
import { ErrorOption, useForm } from "react-hook-form";
import { object, string } from "yup";
import {
  CurrentUserDocument,
  LoginResponseStatus,
  MeResponseStatus,
  useLoginMutation,
} from "../../hooks.generated";
import { yupResolver } from "@hookform/resolvers/yup";
import { t, Trans } from "@lingui/macro";
import { setCookie } from "../../utils/cookie";
import { Button, TextField } from "@mui/material";

interface LoginFormProps {
  onSubmit(): void;
}

interface LoginData {
  email: string;
  password: string;
}

const loginValidation = object({
  email: string().required().email(),
  password: string().required().min(8),
});

const LoginForm = ({ onSubmit }: LoginFormProps) => {
  const client = useApolloClient();
  const [login, { loading }] = useLoginMutation();

  const {
    handleSubmit,
    setError,
    register: registerField,
    formState: { errors },
  } = useForm<LoginData>({
    resolver: yupResolver(loginValidation),
  });

  const loginErrors: { [key: string]: ErrorOption } = {
    [LoginResponseStatus.InvalidCredentials]: {
      message: t`Invalid credentials`,
    },
    [LoginResponseStatus.SomethingWentWrong]: {
      message: t`Something went wrong`,
    },
  };

  const submit = async ({ email, password }: LoginData) => {
    try {
      const { data } = await login({
        variables: {
          input: {
            email,
            password,
          },
        },
      });

      if (data?.login?.status === LoginResponseStatus.Success) {
        setCookie("token", String(data.login.token));

        client.writeQuery({
          query: CurrentUserDocument,
          data: {
            me: {
              status: MeResponseStatus.Success,
              user: data?.login?.user,
            },
          },
        });

        onSubmit && onSubmit();

        return;
      }

      setError("email", loginErrors[String(data?.login?.status)]);
    } catch (exception) {
      console.error(exception);

      setError("email", {
        message: t`Something went wrong`,
      });
    }
  };

  return (
    <form onSubmit={handleSubmit(submit)}>
      <TextField
        margin="normal"
        required
        fullWidth
        type={"email"}
        autoComplete="email"
        autoFocus
        disabled={loading}
        label={<Trans>Email address</Trans>}
        helperText={errors.email?.message}
        error={!!errors.email?.message}
        {...registerField("email")}
      />

      <TextField
        margin="normal"
        required
        fullWidth
        autoComplete="password"
        type={"password"}
        disabled={loading}
        label={<Trans>Password</Trans>}
        helperText={errors.password?.message}
        error={!!errors.password?.message}
        {...registerField("password")}
      />

      <Button
        type={"submit"}
        disabled={loading}
        fullWidth
        variant="contained"
        sx={{ mt: 5 }}
      >
        <Trans>Login</Trans>
      </Button>
    </form>
  );
};

export default LoginForm;
