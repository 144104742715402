import React from "react";

import { I18nProvider } from "@lingui/react";
import { i18n } from "@lingui/core";
import { RouterProvider } from "react-router-dom";
import router from "./Router";
import { ApolloProvider } from "@apollo/client";
import { useApollo } from "./hooks/useApollo";
import { CssBaseline } from "@mui/material";
import { ThemeProvider } from "@emotion/react";
import { theme } from "./styles/theme";
import { GlobalStyles } from "./styles/Global";

const Providers = () => {
  const apolloClient = useApollo();

  return (
    <ApolloProvider client={apolloClient}>
      <I18nProvider i18n={i18n} forceRenderOnLocaleChange={false}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <GlobalStyles />

          <RouterProvider router={router} />
        </ThemeProvider>
      </I18nProvider>
    </ApolloProvider>
  );
};

export default Providers;
