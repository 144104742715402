import React from "react";
import { Tracker, usePaginateTrackersQuery } from "../../../hooks.generated";
import {
  Alert,
  Box,
  Chip,
  LinearProgress,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Paper,
} from "@mui/material";
import { Trans } from "@lingui/macro";
import Pagination from "../../utils/Pagination";
import { usePagination } from "../../../hooks/usePagination";
import { useSearchParams } from "react-router-dom";

interface PaginateTrackersProps {
  onChange?(tracker: Tracker): void;
}

const PaginateTrackers = ({ onChange }: PaginateTrackersProps) => {
  const [searchParameters, setSearchParams] = useSearchParams();
  const { pagination, changePage } = usePagination({
    limit: 200,
  });

  const currentTrackerId = searchParameters.get("tracker");

  const {
    data: trackersData,
    error: trackersError,
    loading: trackersLoading,
  } = usePaginateTrackersQuery({
    variables: {
      pagination,
    },
  });

  if (trackersError) {
    throw trackersError;
  }

  return (
    <Box
      component={Paper}
      padding={2}
      sx={{ display: "flex", flexDirection: "column", height: "100%" }}
    >
      {!trackersLoading &&
        !trackersData?.paginateTrackers.trackers?.data.length && (
          <Alert severity="info">
            <Trans>No trackers found.</Trans>
          </Alert>
        )}

      {trackersLoading && <LinearProgress />}

      <List
        sx={{
          width: "100%",
          position: "relative",
          overflow: "auto",
        }}
      >
        {trackersData?.paginateTrackers.trackers?.data.map((tracker) => (
          <ListItem key={tracker.id} dense>
            <ListItemButton
              selected={tracker.id === currentTrackerId}
              onClick={() => {
                searchParameters.set("tracker", tracker.id);
                searchParameters.delete("ts.page");
                searchParameters.delete("tr.page");
                searchParameters.delete("tracked_session");

                setSearchParams(searchParameters);

                if (onChange) {
                  onChange(tracker as Tracker);
                }
              }}
            >
              <ListItemText primary={tracker.name} sx={{ mr: 5 }} />

              {(tracker.domains || []).map((domain, index) => (
                <Chip label={domain} sx={{ mr: 1 }} key={index} size="small" />
              ))}

              {!tracker.domains?.length && (
                <Chip
                  label={<Trans>all websites</Trans>}
                  sx={{ mr: 1 }}
                  size="small"
                />
              )}
            </ListItemButton>
          </ListItem>
        ))}
      </List>

      <Pagination
        current={trackersData?.paginateTrackers.trackers?.currentPage}
        total={trackersData?.paginateTrackers.trackers?.total}
        limit={trackersData?.paginateTrackers.trackers?.limit}
        onPageChange={(page) => changePage(page)}
      />
    </Box>
  );
};

export default PaginateTrackers;
