import { useMemo } from "react";
import {
  ApolloClient,
  HttpLink,
  InMemoryCache,
  NormalizedCacheObject,
  from,
} from "@apollo/client";

import { ErrorLink } from "@apollo/client/link/error";
import { setContext } from "@apollo/client/link/context";
import { getCookie } from "../utils/cookie";

let apolloClient: ApolloClient<NormalizedCacheObject>;

function createLink() {
  const httpLink = new HttpLink({
    uri:
      process.env.REACT_APP_GRAPHQL_HTTP_SERVER_URL ||
      "http://" + window.location.hostname + ":8000/graphql",
  });

  return httpLink;
}

function createApolloClient() {
  const cache = new InMemoryCache();

  const link = createLink();

  const errorLink = new ErrorLink((error) => {
    console.error(error);
  });

  const authLink = setContext((operation, { headers }) => {
    const token = getCookie("token");

    return {
      headers: {
        ...headers,
        Authorization: token ? `Bearer ${token}` : "",
      },
    };
  });

  return new ApolloClient({
    cache,
    link: from([errorLink, authLink, link]),
  });
}

export function initializeApollo(url = undefined, headers = {}) {
  const _apolloClient = apolloClient ?? createApolloClient();

  // Create the Apollo Client once in the client
  if (!apolloClient) {
    apolloClient = _apolloClient;
  }

  return _apolloClient;
}

export function useApollo() {
  return useMemo(() => initializeApollo(), []);
}
