import React from "react";
import { Permission } from "../../../hooks.generated";
import { Alert, Box, Grid } from "@mui/material";
import PaginateTrackedSessions from "../../elements/trackers/PaginateTrackedSessions";
import { useSearchParams } from "react-router-dom";
import TrackedSessionDetails from "../../elements/trackers/TrackedSessionDetails";
import { Trans } from "@lingui/macro";

export const trackersPagePermissions = [Permission.TrackerIndex];

const TrackersPage = () => {
  const [searchParameters] = useSearchParams();
  const trackerId = searchParameters.get("tracker");
  const trackedSessionId = searchParameters.get("tracked_session");

  return (
    <>
      {!trackerId && (
        <Box padding={3}>
          <Alert severity="info">
            <Trans>No tracker selected.</Trans>
          </Alert>
        </Box>
      )}
      {!!trackerId && (
        <Box sx={{ height: "100%", flexDirection: "column", display: "flex" }}>
          {/* <Box sx={{ flex: "0 1 auto", background: "red" }}>
            <Grid container sx={{ height: "100%", flexDirection: "row" }}>
              <Grid item xs={12} padding={3} sx={{ flex: "0 1 auto" }}>
                <Box component={Paper} padding={2}></Box>
              </Grid>
            </Grid>
          </Box> */}

          <Box sx={{ flex: "1 1 auto", height: "100%" }}>
            <Grid container sx={{ height: "100%", flexDirection: "row" }}>
              <Grid item xs={12} sm={3} padding={3} sx={{ height: "100%" }}>
                <PaginateTrackedSessions trackerId={trackerId} />
              </Grid>

              {!!trackerId && !!trackedSessionId && (
                <Grid item xs={12} sm={9} padding={3} sx={{ height: "100%" }}>
                  <TrackedSessionDetails trackedSessionId={trackedSessionId} />
                </Grid>
              )}
            </Grid>
          </Box>
        </Box>
      )}
    </>
  );
};

export default TrackersPage;
